import { useHistory, useLocation } from 'react-router-dom';

export function useSetQueryParams() {
  const history = useHistory();
  const location = useLocation();

  return (newParams) => {
    const searchParams = new URLSearchParams(location.search);

    Object.entries(newParams).forEach(([key, value]) => {
      if (value === null || value === undefined) {
        searchParams.delete(key);
      } else {
        searchParams.set(key, value);
      }
    });

    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };
}
