import React from 'react';
import { Modal } from 'rsuite';
import { InputNumber } from 'rsuite';
import Button from 'react-bootstrap/Button';
import './SelectedTerminalsModal.scss';

const SelectedTerminalsModal = ({
  show,
  onClose,
  clearTerminalQuantities,
  groupedTerminals,
  terminalQuantities,
  handleInputNumberChange,
  printTerminalQuantities,
}) => {
  return (
    <Modal show={show} onHide={onClose} backdrop='static' centered>
      <Modal.Header closeButton className='modal-header'>
        <Modal.Title className='modal-title'>Selected Terminals</Modal.Title>
      </Modal.Header>
      <Modal.Body className='modal-body'>
        {groupedTerminals.map((merchantGroup, index) => (
          <div key={index} className='merchant-group'>
            <h5 className='merchant-name'>{merchantGroup.merchantName}</h5>
            {merchantGroup.terminals.length > 0 ? (
              <div style={{ paddingLeft: '10px' }}>
                {merchantGroup.terminals.map((terminal, idx) => (
                  <div key={idx} className='terminal-container'>
                    <div>
                      <strong className='sub-merchant-details'>
                        Location:
                      </strong>{' '}
                      {terminal.subMerchantLocation}
                    </div>
                    <div>
                      <strong className='sub-merchant-details'>
                        Terminals:
                      </strong>
                      {terminal.terminals.length > 0 ? (
                        terminal.terminals.map((term, termIdx) => (
                          <div key={termIdx} className='terminal-item'>
                            {term}
                            <InputNumber
                              value={terminalQuantities[term] || 0}
                              max={100}
                              min={0}
                              onChange={(value) =>
                                handleInputNumberChange(term, value)
                              }
                              className='input-number'
                            />
                          </div>
                        ))
                      ) : (
                        <span className='no-terminals-text'>No terminals</span>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p className='no-terminals'>No terminals</p>
            )}
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='secondary'
          onClick={printTerminalQuantities}
          className='button'
        >
          Print QR Codes
        </Button>
        <Button
          variant='secondary'
          onClick={clearTerminalQuantities}
          className='button'
        >
          Clear
        </Button>
        <Button variant='secondary' onClick={onClose} className='close-button'>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SelectedTerminalsModal;
