import React from 'react';
import styles from './OrderViewCard.module.scss';

const OrderViewCard = ({ orderCode, brandName, status, isActive, onClick }) => {
  const statusMapping = {
    NEW: 'ORDER PLACED',
    QUOTATION_GENERATED: 'QUOTATION GENERATED',
    INVOICE_GENERATED: 'INVOICE GENERATED',
    PARTIALLY_PAID: 'PARTIALLY PAID',
    PAID: 'PAID',
    PAYMENT_SKIPPED: 'PAYMENT SKIPPED',
  };

  const statusColorMapping = {
    NEW: '#768fdb',
    QUOTATION_GENERATED: '#fcd68f',
    INVOICE_GENERATED: '#fcd68f',
    PARTIALLY_PAID: '#9dc29d',
    PAID: '#1fc71f',
    PAYMENT_SKIPPED: '#f56e6e',
  };

  const mappedStatus = statusMapping[status];
  const statusColor = statusColorMapping[status];

  return (
    <div
      className={`${styles.cardContainer} ${isActive ? styles.active : ''}`}
      onClick={onClick}
    >
      <div className={styles.cardContent}>
        <div className={styles.orderCode}>{orderCode}</div>
        <div className={styles.brandName}>{brandName}</div>
        <div
          className={styles.statusBadge}
          style={{ backgroundColor: statusColor }}
        >
          {mappedStatus}
        </div>
      </div>
    </div>
  );
};

export default OrderViewCard;
