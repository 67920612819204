/* Utility function to format the "name" like this;
ex1:- example_name_one => Example Name One
ex2:- EXAMPLE_NAME_TWO => Example Name Two
*/

function formatPackagingName(name) {
  // 1) Replace underscores with spaces
  const spaced = name.replace(/_/g, ' ');

  // 2) Split by spaces and convert each word to Title Case
  const titleCasedWords = spaced.split(' ').map((word) => {
    const lower = word.toLowerCase();
    return lower.charAt(0).toUpperCase() + lower.slice(1);
  });

  // 3) Join them back into a single string
  return titleCasedWords.join(' ');
}

export { formatPackagingName };
