import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styles from './CreateVoucher.module.scss';
import { PageHeader } from '..';
import { Button, Checkbox, CheckboxGroup, Radio, RadioGroup } from 'rsuite';
import useComponentState from '../../utils/useComponentState';
import { createVoucher } from '../../api/Client';
import { getVoucherThemes } from '../../api/Products';
import { toast } from 'react-toastify';
import { Dropdown } from 'react-bootstrap';
import useDebounce from '../../utils/useDebounce';

const CreateVoucher = (props) => {
  const [state, setState] = useComponentState({
    isSelfActivation: false,
    isPersonalized: false,
    voucherThemes: [],
    selectedTheme: null,
    themesType: 'themesFromClient',
    searchTerm: '',
    page: 0,
    limit: 5,
    clientRef: props.activeOrder.clientRef,
  });

  // Debounce the search term with a delay (e.g., 300ms)
  const debouncedSearchTerm = useDebounce(state.searchTerm, 300);

  const selectedValues = [];
  if (state.isSelfActivation) selectedValues.push('self');
  if (state.isPersonalized) selectedValues.push('personalized');

  // First effect: update clientRef when activeOrder changes
  useEffect(() => {
    setState({ clientRef: props.activeOrder.clientRef });
  }, [props.activeOrder.clientRef]);

  // Second effect: fetch themes whenever clientRef, themesType, or page changes
  useEffect(() => {
    fetchVoucherThemes();
  }, [state.clientRef, state.themesType, state.page]);

  const fetchVoucherThemes = async () => {
    const token = props.auth.auth.token;
    const clientRef =
      state.themesType === 'themesFromClient' ? state.clientRef : null;
    try {
      const response = await getVoucherThemes(
        token,
        clientRef,
        state.page,
        state.limit
      );
      // Transform the response to a flat array of themes
      const themes = response.categories.flatMap((category) =>
        category.products.map((product) => ({
          products: product,
        }))
      );

      // If no themes are returned and not on the first page,
      // notify the user and reset the page to 0 so the first page themes are loaded.
      if (themes.length === 0 && state.page !== 0) {
        toast.info('No more themes available, returning to the first page');
        setState({ page: 0 });
      } else {
        setState({ voucherThemes: themes });
      }
    } catch (error) {
      console.error('Error fetching voucher themes:', error);
    }
  };

  const handleSelectTheme = (theme) => {
    setState({ selectedTheme: theme });
  };

  const displayToast = (message, type) => {
    if (type === 'success') {
      toast.success(message);
    } else if (type === 'error') {
      toast.error(message);
    }
  };

  const handleCreateVoucher = async () => {
    if (!state.selectedTheme) {
      toast.error('Please select a voucher theme');
      return;
    }

    const payload = {
      clientOrderId: props.activeOrder.clientOrderId,
      generateSelfActivateVouchers: state.isSelfActivation,
      generateIndividualVouchers: state.isPersonalized,
      productId: state.selectedTheme.products.id,
      themeId: state.selectedTheme.themes.id,
    };

    try {
      const response = await createVoucher(props.auth.auth.token, payload);
      if (response.message === 'Vouchers created successfully') {
        displayToast('Vouchers created successfully', 'success');
        setState({
          isSelfActivation: false,
          isPersonalized: false,
          selectedTheme: null,
        });
      }
    } catch (error) {
      displayToast('Voucher creation failed', 'error');
    }
  };

  // Filter voucher themes using the debounced search term (client-side filtering)
  const filteredThemes = state.voucherThemes.filter((theme) => {
    const search = debouncedSearchTerm.toLowerCase();
    return (
      theme.products.title.toLowerCase().includes(search) ||
      theme.products.description.toLowerCase().includes(search)
    );
  });

  // Use filtered themes only if a search term is provided; otherwise display all voucher themes.
  const themesToDisplay =
    debouncedSearchTerm.trim().length > 0
      ? filteredThemes
      : state.voucherThemes;

  return (
    <div className={styles.div_root}>
      <PageHeader text='Create Vouchers' />

      <div className={styles.voucherRoot}>
        <div>
          <CheckboxGroup
            inline
            name='checkbox-group'
            value={selectedValues}
            onChange={(values) =>
              setState({
                isSelfActivation: values.includes('self'),
                isPersonalized: values.includes('personalized'),
              })
            }
          >
            <Checkbox value='self'>Create Self Activation Vouchers</Checkbox>
            <Checkbox value='personalized'>
              Create Personalized Vouchers
            </Checkbox>
          </CheckboxGroup>
        </div>

        <div>
          <RadioGroup
            name='radio-group-inline'
            inline
            defaultValue='themesFromClient'
            value={state.themesType}
            onChange={(value) =>
              setState({ themesType: value, page: 0, searchTerm: '' })
            }
          >
            <Radio value='themesFromClient'>
              {props.activeOrder.clientName} Themes
            </Radio>
            <Radio value='themesFromThyaga'>Thyaga Themes</Radio>
          </RadioGroup>
        </div>
        <div className={styles.dropdownContainer}>
          <Dropdown className={`w-100 ${styles.customDropdown}`}>
            <Dropdown.Toggle
              // Remove inline styling here
              variant='light'
              id='dropdown-theme'
            >
              {state.selectedTheme
                ? `${state.selectedTheme.products.title} theme ${state.selectedTheme.themes.id}`
                : 'Select Voucher Type'}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {/* Search bar at top */}
              {state.voucherThemes.length > 0 && (
                <div className={styles.dropdownSearch}>
                  <input
                    type='text'
                    placeholder='Search themes...'
                    value={state.searchTerm}
                    onChange={(e) => setState({ searchTerm: e.target.value })}
                  />
                </div>
              )}

              {/* Theme list */}
              {themesToDisplay.length > 0 ? (
                themesToDisplay.flatMap((theme) =>
                  theme.products.themes.map((themeInProduct) => (
                    <Dropdown.Item
                      key={`${theme.products.id}-${themeInProduct.id}`}
                      onClick={() =>
                        handleSelectTheme({ ...theme, themes: themeInProduct })
                      }
                      className={styles.dropdownItem}
                    >
                      <div className={styles.themeRow}>
                        <img
                          src={themeInProduct.image}
                          alt={`${theme.products.title} ${themeInProduct.id}`}
                          className={styles.themeImage}
                        />
                        <p className={styles.themeName}>
                          {theme.products.title} theme {themeInProduct.id}
                        </p>
                      </div>
                    </Dropdown.Item>
                  ))
                )
              ) : (
                <Dropdown.Item disabled>
                  {debouncedSearchTerm.trim().length > 0 ? (
                    'No results found'
                  ) : (
                    <>
                      No themes available
                      <br />
                      try Thyaga themes
                    </>
                  )}
                </Dropdown.Item>
              )}

              {/* Pagination controls */}
              {state.voucherThemes.length > 0 && (
                <div className={styles.dropdownPagination}>
                  <Button
                    onClick={() =>
                      setState({ page: Math.max(0, state.page - 1) })
                    }
                    disabled={state.page === 0}
                  >
                    Prev
                  </Button>
                  <span>Page {state.page + 1}</span>
                  <Button onClick={() => setState({ page: state.page + 1 })}>
                    Next
                  </Button>
                </div>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <Button
          appearance='primary'
          color='green'
          className={styles.buttonGroup}
          onClick={handleCreateVoucher}
        >
          Create Vouchers
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(CreateVoucher);
