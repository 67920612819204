import { useLocation } from 'react-router-dom';

export function useGetQueryParams(allowedParams = {}) {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const queryObject = {};

  Object.values(allowedParams).forEach((paramKey) => {
    if (paramKey && params.has(paramKey)) {
      queryObject[paramKey] = params.get(paramKey);
    }
  });

  return queryObject;
}
