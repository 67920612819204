import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import styles from './OrderDetailsModal.module.scss';

class OrderDetailsModal extends Component {
  render() {
    const { show, onHide, voucher, csvData } = this.props;

    const headers =
      csvData && csvData.length > 0
        ? Object.keys(csvData[0]).filter(
            (header) => header.trim().toLowerCase() !== 'no'
          )
        : [];

    return (
      <Modal
        size='lg'
        className={styles.orderDetailsModal}
        show={show}
        onHide={onHide}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {voucher && voucher.amount
              ? `All ${voucher.amount} Value Details`
              : 'Voucher Details'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {voucher ? (
            <div className={styles.tableResponsive}>
              {csvData && csvData.length > 0 ? (
                <table className={styles.csvTable}>
                  <thead>
                    <tr>
                      {headers.map((header, index) => (
                        <th key={index}>{header}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {csvData.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {headers.map((header, cellIndex) => (
                          <td key={cellIndex}>{row[header]}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>No data available.</p>
              )}
            </div>
          ) : (
            <p>No voucher details available.</p>
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

export default OrderDetailsModal;
