const connectPrinter = async () => {
  try {
    const device = await navigator.usb.requestDevice({
      filters: [{ vendorId: 0x0a5f }],
    });

    await device.open();
    await device.selectConfiguration(1);
    await device.claimInterface(0);

    return device;
  } catch (error) {
    console.error(error);
  }
};

export default connectPrinter;
