import React, { useEffect } from 'react';
import { IconButton, Loader } from 'rsuite';
import styles from './VoucherSideBar.module.scss';
import OrderViewCard from '../OrderViewCard/OrderViewCard';
import { searchClientOrders } from '../../api/Client';
import useComponentState from '../../utils/useComponentState';
import { connect } from 'react-redux';
import PlusIcon from '@rsuite/icons/Plus';

const VoucherSideBar = ({
  orders,
  activeOrder,
  onOrderSelect,
  isLoading,
  currentPage,
  pageSize,
  onPrevPage,
  onNextPage,
  auth,
  onAddNewOrder,
}) => {
  // Extend the state to include searchQuery and searchResults.
  const [state, setState] = useComponentState({
    searchQuery: '',
    searchResults: null,
  });

  // Debounced effect: when the search query changes, trigger a search after 500ms.
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (state.searchQuery.trim() !== '') {
        handleSearch(state.searchQuery);
      } else {
        // If search is cleared, remove search results.
        setState({ searchResults: null });
      }
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [state.searchQuery]);

  const handleSearch = async (query) => {
    try {
      const result = await searchClientOrders(auth.auth.token, query, 0, 10);
      setState({ searchResults: result.list });
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  // Decide which orders to display: search results (if any) or the default orders.
  const displayOrders = state.searchQuery ? state.searchResults : orders;

  return (
    <div className={styles.sidebarContainer}>
      <div className={styles.header}>
        <h5>Order Details View</h5>
        {/* Clicking the PlusIcon triggers the onAddNewOrder callback */}
        <IconButton icon={<PlusIcon />} onClick={onAddNewOrder} />
      </div>
      <div className={styles.searchContainer}>
        <input
          type='text'
          placeholder='Search orders...'
          className={styles.searchInput}
          value={state.searchQuery}
          onChange={(e) => setState({ searchQuery: e.target.value })}
        />
      </div>
      {isLoading ? (
        <Loader style={{ paddingInline: '20px' }} content='Loading orders...' />
      ) : displayOrders && displayOrders.length > 0 ? (
        displayOrders.map((order, index) => (
          <OrderViewCard
            key={`${order.clientOrderId}-${index}`}
            orderCode={order.clientOrderId}
            brandName={order.clientName}
            status={order.paymentStatus}
            isActive={
              activeOrder && activeOrder.clientOrderId === order.clientOrderId
            }
            onClick={() => onOrderSelect(order)}
          />
        ))
      ) : (
        <p className={styles.noOrders}>No orders yet.</p>
      )}
      <div className={styles.pagination}>
        <button disabled={currentPage === 0} onClick={onPrevPage}>
          Prev
        </button>
        <span> Page {currentPage + 1}</span>
        <button disabled={orders.length < pageSize} onClick={onNextPage}>
          Next
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(VoucherSideBar);
