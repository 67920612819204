import { GET, POST_FILE_AND_JSON_DATA } from './core';

export const getEndpointWithPrefix = (endpoint) => {
  return `/client/${endpoint}`;
};

export const getClientOrders = (token, currentPage, pageSize) => {
  const endpoint = getEndpointWithPrefix(
    `getClientOrders?page=${currentPage}&pageSize=${pageSize}&sortBy=dateTime&order=desc`
  );
  return GET(endpoint, token);
};

export const getAllSalesAgents = (token) => {
  const endpoint = '/sales-agent';
  return GET(endpoint, token);
};

export const createPlaceOrder = (payload, token) => {
  const endpoint = getEndpointWithPrefix('place-order');
  return POST_FILE_AND_JSON_DATA(endpoint, payload, token);
};
