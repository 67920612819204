import React, { useEffect } from 'react';
import PlaceOrder from '../../components/PlaceOrder/PlaceOrder';
import StepProgressBar from '../../components/StepProgressBar/StepProgressBar';
import VoucherSideBar from '../../components/VoucherSideBar/VoucherSideBar';
import GenerateInvoice from '../../components/GenerateInvoice/GenerateInvoice';
import CreateVoucher from '../../components/CreateVoucher/CreateVoucher';
import styles from './PlaceClientOrder.module.scss';
import { getClientOrders } from '../../api/clientOrder';
import { connect } from 'react-redux';
import useComponentState from '../../utils/useComponentState';
import OrderDetails from '../../components/PlaceOrderDetails/OrderDetails';
import UploadSlip from '../../components/UploadSlip/UploadSlip';

const PlaceClientOrder = (props) => {
  const [state, setState] = useComponentState({
    orders: [],
    activeOrder: null,
    orderProgress: {},
    isLoading: true,
    manualStep: null, // manualStep can be 'goToStep1', 'goToStep2', or 'goToStep3'
    currentPage: 0,
    pageSize: 10,
  });

  useEffect(() => {
    fetchOrders(0);
  }, []);

  const fetchOrders = async (page = state.currentPage) => {
    setState({ isLoading: true });
    try {
      const { pageSize } = state;
      const result = await getClientOrders(
        props.auth.auth.token,
        page,
        pageSize
      );
      setState({
        orders: result.list,
        currentPage: page,
        isLoading: false,
        activeOrder: result.list[0],
        manualStep: null,
      });
    } catch (error) {
      console.error('Error fetching orders:', error);
      setState({ isLoading: false });
    }
  };

  const handleAddNewOrder = () => {
    setState({ activeOrder: null, manualStep: null });
  };

  // Centralized function to refresh order data
  const refreshOrder = async (orderId) => {
    try {
      const { currentPage, pageSize } = state;
      const result = await getClientOrders(
        props.auth.auth.token,
        currentPage,
        pageSize
      );
      const updatedActiveOrder = result.list.find(
        (order) => order.clientOrderId === orderId
      );
      if (updatedActiveOrder) {
        const progress = convertStatusToProgress(
          updatedActiveOrder.paymentStatus
        );
        setState((prevState) => ({
          ...prevState,
          orders: result.list,
          activeOrder: updatedActiveOrder,
          orderProgress: {
            ...prevState.orderProgress,
            [updatedActiveOrder.clientOrderId]: progress,
          },
        }));
      }
    } catch (error) {
      console.error('Error refreshing order:', error);
    }
  };

  // Mapping of backend statuses to progress steps (1-indexed)
  const statusMapping = {
    NEW: 1,
    QUOTATION_GENERATED: 2,
    INVOICE_GENERATED: 2,
    PARTIALLY_PAID: 3,
    PAYMENT_SKIPPED: 3,
    PAID: 3,
    VOUCHER_CREATED: 4,
  };

  // Define the allowed manual steps for each status.
  const statusStepsMap = {
    NEW: ['goToStep1', 'goToStep2'],
    QUOTATION_GENERATED: ['goToStep1', 'goToStep2', 'goToStep3'],
    INVOICE_GENERATED: ['goToStep1', 'goToStep2', 'goToStep3'],
    PARTIALLY_PAID: ['goToStep1', 'goToStep2', 'goToStep3', 'goToStep4'],
    PAID: ['goToStep1', 'goToStep2', 'goToStep3', 'goToStep4'],
    PAYMENT_SKIPPED: ['goToStep1', 'goToStep2', 'goToStep3', 'goToStep4'],
  };

  const convertStatusToProgress = (paymentStatus) => {
    const currentStep = statusMapping[paymentStatus] || 0;
    const stepsStatus = [false, false, false, false].map(
      (_, index) => index < currentStep
    );
    return { stepsStatus, currentStep };
  };

  // Update progress now only calls refreshOrder manualStep
  const updateProgress = (newOrder) => {
    refreshOrder(newOrder.clientOrderId);
    setState((prevState) => ({
      ...prevState,
      manualStep: null,
    }));
  };

  const handleOrderSelect = (activeOrder) => {
    const progress = convertStatusToProgress(activeOrder.paymentStatus);
    setState((prevState) => ({
      ...prevState,
      activeOrder,
      orderProgress: {
        ...prevState.orderProgress,
        [activeOrder.clientOrderId]: progress,
      },
      manualStep: null, // Reset manual step when a different order is selected
    }));
  };

  // Handle click events from the progress bar.
  const handleStepClick = (stepIndex) => {
    // Do nothing if loading or if there is no active order.
    if (state.isLoading || !state.activeOrder) return;

    const currentStatus = state.activeOrder.paymentStatus;
    const allowedSteps = statusStepsMap[currentStatus];

    // If the current status is mapped and the step index is within the allowed range, update the manualStep.
    if (allowedSteps && stepIndex >= 0 && stepIndex < allowedSteps.length) {
      setState({ manualStep: allowedSteps[stepIndex] });
    } else {
      setState({ manualStep: null });
    }
  };

  const progress = state.activeOrder
    ? ['PAID', 'PARTIALLY_PAID', 'PAYMENT_SKIPPED'].includes(
        state.activeOrder.paymentStatus
      )
      ? convertStatusToProgress(state.activeOrder.paymentStatus)
      : state.activeOrder.paymentStatus === 'NEW'
      ? convertStatusToProgress('NEW')
      : convertStatusToProgress(
          state.manualStep === 'goToStep2'
            ? 'INVOICE_GENERATED'
            : state.activeOrder.paymentStatus
        )
    : { stepsStatus: [false, false, false, false], currentStep: 0 };

  let disabledSteps = [];
  if (state.isLoading || !state.activeOrder) {
    disabledSteps = [0, 1, 2, 3];
  } else if (state.activeOrder.paymentStatus === 'NEW') {
    disabledSteps = [2, 3];
  } else if (
    state.activeOrder.paymentStatus === 'QUOTATION_GENERATED' ||
    state.activeOrder.paymentStatus === 'INVOICE_GENERATED'
  ) {
    disabledSteps = [3];
  } else if (
    state.activeOrder.paymentStatus === 'PARTIALLY_PAID' ||
    state.activeOrder.paymentStatus === 'PAID' ||
    state.activeOrder.paymentStatus === 'PAYMENT_SKIPPED'
  ) {
    disabledSteps = [];
  }

  const handleNextPage = () => {
    if (state.orders.length < state.pageSize) return;
    fetchOrders(state.currentPage + 1);
  };

  const handlePrevPage = () => {
    if (state.currentPage === 0) return;
    fetchOrders(state.currentPage - 1);
  };

  const renderMainComponent = () => {
    // Mapping for manual steps
    const manualStepComponents = {
      goToStep1: (
        <OrderDetails
          activeOrder={state.activeOrder}
          updateProgress={updateProgress}
        />
      ),
      goToStep2: (
        <GenerateInvoice
          activeOrder={state.activeOrder}
          refreshOrdersAfterInvoiceGeneration={refreshOrder}
        />
      ),
      goToStep3: (
        <UploadSlip
          activeOrder={state.activeOrder}
          updateProgress={updateProgress}
          refreshOrdersAfterSlipUpload={refreshOrder}
          setManualStep={(step) =>
            setState((prev) => ({ ...prev, manualStep: step }))
          }
        />
      ),
      goToStep4: (
        <CreateVoucher activeOrder={state.activeOrder} CreateVoucher />
      ),
    };

    // If a manual step is set, render its component
    if (state.manualStep && manualStepComponents[state.manualStep]) {
      return manualStepComponents[state.manualStep];
    }

    // If no active order exists, display PlaceOrder
    if (!state.activeOrder) {
      return <PlaceOrder updateProgress={updateProgress} />;
    }

    // Mapping for order status-based components
    const statusComponents = {
      NEW: (
        <GenerateInvoice
          activeOrder={state.activeOrder}
          refreshOrdersAfterInvoiceGeneration={refreshOrder}
        />
      ),
      QUOTATION_GENERATED: (
        <UploadSlip
          activeOrder={state.activeOrder}
          updateProgress={updateProgress}
          refreshOrdersAfterSlipUpload={refreshOrder}
          setManualStep={(step) =>
            setState((prev) => ({ ...prev, manualStep: step }))
          }
        />
      ),

      INVOICE_GENERATED: (
        <UploadSlip
          activeOrder={state.activeOrder}
          updateProgress={updateProgress}
          refreshOrdersAfterSlipUpload={refreshOrder}
          setManualStep={(step) =>
            setState((prev) => ({ ...prev, manualStep: step }))
          }
        />
      ),
      PARTIALLY_PAID: <CreateVoucher activeOrder={state.activeOrder} />,
      PAYMENT_SKIPPED: <CreateVoucher activeOrder={state.activeOrder} />,
      PAID: <CreateVoucher activeOrder={state.activeOrder} />,
    };

    // Render component based on active order's paymentStatus,
    // or fallback to PlaceOrder if no match is found.
    return (
      statusComponents[state.activeOrder.paymentStatus] || (
        <PlaceOrder
          activeOrder={state.activeOrder}
          updateProgress={updateProgress}
        />
      )
    );
  };

  return (
    <div className={styles.main_container}>
      <div className={styles.left_container}>
        <VoucherSideBar
          orders={state.orders}
          activeOrder={state.activeOrder}
          onOrderSelect={handleOrderSelect}
          isLoading={state.isLoading}
          currentPage={state.currentPage}
          pageSize={state.pageSize}
          onPrevPage={handlePrevPage}
          onNextPage={handleNextPage}
          onAddNewOrder={handleAddNewOrder}
        />
      </div>
      <div className={styles.right_container}>
        <div className={styles.mainContent}>
          <StepProgressBar
            stepsStatus={progress.stepsStatus}
            currentStep={progress.currentStep}
            onStepClick={handleStepClick}
            disabledSteps={disabledSteps}
          />
          {renderMainComponent()}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PlaceClientOrder);
