// import React from 'react';
// import moment from 'moment';
// import EditableCell from '../editableCell/EditableCelll.component';

// import Table from 'react-bootstrap/Table';
// import Spinner from 'react-bootstrap/Spinner';

// import 'bootstrap/dist/css/bootstrap.min.css';
// import styles from './DataTable.module.scss';
// import {
//   faEdit,
//   faMinus,
//   faPlus,
//   faTrash,
// } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

// const DataTable = ({
//   tableData,
//   tableheading,
//   handleOnClick,
//   tag,
//   editableCell,
//   handleAddRow,
//   handleRemoveRow,
//   fetchError,
//   search_filter_loading,
//   renderTableDropdown,
//   notFoundMessage,
//   merchant,
//   renderTerminalsDropdown,
// }) => {
//   // Define safe fallbacks for tableheading and tableData.
//   const safeTableHeading =
//     tableheading && tableheading.column ? tableheading.column : [];
//   const safeTableData = Array.isArray(tableData) ? tableData : [];

//   // Renders table headings using safeTableHeading
//   const renderTableHeadings = () => {
//     return safeTableHeading.map((data, index) => (
//       <th key={index} className={styles.tableHead} align='center'>
//         {data}
//       </th>
//     ));
//   };

//   // ==============================
//   // Row-level render functions
//   // ==============================

//   const renderUploadHistory = (data) => (
//     <tr onClick={() => handleOnClick(data.batchId)} key={data.batchId}>
//       <td>{data.batchId}</td>
//       <td>{data.merchantVoucherStore}</td>
//       <td>{moment(data.createdDate).format('DD/MM/YYYY hh:mm A')}</td>
//       <td>{data.status}</td>
//       <td>{data.uploadedBy}</td>
//     </tr>
//   );

//   const truncateString = (str, num) => {
//     if (!str) return '';
//     return str.length <= num ? str : str.substring(0, num) + '...';
//   };

//   const renderTransactionHistory = (data) => (
//     <tr
//       onClick={() => handleOnClick(data.redemptionId)}
//       key={data.redemptionId}
//     >
//       <td>{moment(data.timestamp).format('DD/MM/YYYY hh:mm A')}</td>
//       <td>{truncateString(data.description, 25)}</td>
//       <td>{truncateString(data.amount?.toFixed(2).toString(), 10)}</td>
//     </tr>
//   );

//   const renderUserDetails = (data) => (
//     <tr key={data.email}>
//       <td>{data.firstName}</td>
//       <td>{data.lastName}</td>
//       <td>{data.email}</td>
//       <td>{data.mobileNo}</td>
//     </tr>
//   );

//   const renderVoucherDetails = (data) => (
//     <tr onClick={() => handleOnClick(data.id)} key={data.id}>
//       <td>{data.id}</td>
//       <td>{data.ownerName}</td>
//       <td>{data.sender}</td>
//       <td>
//         {data.amount
//           .toFixed(2)
//           .toString()
//           .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
//       </td>
//       <td>{data.ownerMobile}</td>
//     </tr>
//   );

//   const renderRedemptionsData = (data) => (
//     <tr key={data.id}>
//       <td>{data.id}</td>
//       <td>
//         {data.merchantEarning &&
//           data.merchantEarning
//             .toFixed(2)
//             .toString()
//             .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
//       </td>
//     </tr>
//   );

//   const renderSubMerchantData = (data) => (
//     <tr key={data.id}>
//       <td>{data.id}</td>
//       <td>{data.emails.join(', ')}</td>
//       <td>{data.mobileNumber.join(', ')}</td>
//       <td>{data.location}</td>
//       <td style={{ width: '10px' }}>
//         {renderTerminalsDropdown(data.terminals)}
//       </td>
//     </tr>
//   );

//   const renderMerchantData = (data) => (
//     <tr
//       onClick={() => handleOnClick(data.id)}
//       style={{ cursor: 'pointer' }}
//       key={data.id}
//     >
//       <td>{data.id}</td>
//       <td>{data.name}</td>
//       <td>{data.category}</td>
//       <td>{data.status}</td>
//       <td>{data.website}</td>
//       <td>{data.tel}</td>
//     </tr>
//   );

//   const renderTerminalData = (data) => (
//     <tr onClick={() => handleOnClick(data.id)} key={data.id}>
//       <td>{data.id}</td>
//       <td>{data.merchant.name}</td>
//       <td>{data.subMerchant.id}</td>
//       <td>{data.lastActive}</td>
//     </tr>
//   );

//   const renderPaymentData = (data) => (
//     <tr onClick={() => handleOnClick(data.id)} key={data.id}>
//       <td>{moment(data.createdTimestamp).format('DD/MM/YYYY hh:mm A')}</td>
//       {!merchant && <td>{data.id}</td>}
//       <td>{data.referenceCode}</td>
//       {!merchant && <td>{data.coreMerchant.name}</td>}
//       <td>
//         <span className='d-flex justify-content-end w-50'>
//           {data.amount
//             .toFixed(2)
//             .toString()
//             .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
//         </span>
//       </td>
//     </tr>
//   );

//   const renderClientOrder = (data) => (
//     <tr onClick={() => handleOnClick(data.clientOrderId)} key={data.id}>
//       <td>{data.clientOrderId}</td>
//       <td>{data.referenceNo}</td>
//       <td>{moment(data.date).format('DD/MM/YYYY hh:mm A')}</td>
//       <td>{data.client.clientName}</td>
//       <td>{data.totalAmount}</td>
//     </tr>
//   );

//   const renderClientOrderItems = (data) => {
//     const handleSave = (field, newValue) => {
//       console.log(`Updated ${field} to ${newValue} for item ID ${data.id}`);
//     };

//     return (
//       <tr key={data.id}>
//         <EditableCell
//           value={data.amount && data.amount.toFixed(2)}
//           onSave={(newValue) => handleSave('amount', newValue)}
//         />
//         <EditableCell
//           value={data.qty}
//           onSave={(newValue) => handleSave('qty', newValue)}
//         />
//         <td>
//           <OverlayTrigger
//             placement='right'
//             overlay={<Tooltip>{'Remove Row'}</Tooltip>}
//           >
//             <button onClick={() => handleRemoveRow(data.id)}>
//               <FontAwesomeIcon icon={faTrash} />
//             </button>
//           </OverlayTrigger>
//         </td>
//       </tr>
//     );
//   };

//   // Render for invoice details including a grand total row.
//   const renderInvoiceDetails = (data) => (
//     <tr key={data.id}>
//       <td>LKR {data.amount} Multi-Store e-gift card</td>
//       <td>{data.qty}</td>
//       <td>{data.amount.toFixed(2)}</td>
//       <td>{(data.qty * data.amount).toFixed(2)}</td>
//     </tr>
//   );

//   // ==========================
//   // Main switch to render rows
//   // ==========================
//   const renderTableData = () => {
//     switch (tag) {
//       case 'terminal':
//         return safeTableData.map((data) => renderTerminalData(data));
//       case 'payments':
//         return safeTableData.map((data) => renderPaymentData(data));
//       case 'merchants':
//         return safeTableData.map((data) => renderMerchantData(data));
//       case 'subMerchants':
//         return safeTableData.map((data) => renderSubMerchantData(data));
//       case 'redemptions':
//         return safeTableData.map((data) => renderRedemptionsData(data));
//       case 'userDetails':
//         return safeTableData.map((data) => renderUserDetails(data));
//       case 'voucherDetails':
//         return safeTableData.map((data) => renderVoucherDetails(data));
//       case 'denominationUploads':
//         return safeTableData.map((data) => renderUploadHistory(data));
//       case 'transactionDetails':
//         return safeTableData.map((data) => renderTransactionHistory(data));
//       case 'clientDetails':
//         return safeTableData.map((data) => renderClientOrder(data));
//       case 'clientOrderItems':
//         return safeTableData.map((data) => renderClientOrderItems(data));
//       case 'invoiceDetails': {
//         const grandTotal = safeTableData.reduce(
//           (acc, item) => acc + item.qty * item.amount,
//           0
//         );
//         return (
//           <>
//             {safeTableData.map((data) => renderInvoiceDetails(data))}
//             <tr>
//               <td
//                 colSpan={3}
//                 style={{ textAlign: 'center', fontWeight: 'bold' }}
//               >
//                 Grand Total
//               </td>
//               <td style={{ fontWeight: 'bold' }}>{grandTotal.toFixed(2)}</td>
//             </tr>
//           </>
//         );
//       }
//       default:
//         return null;
//     }
//   };

//   // ==========================
//   // Render "no data" or "error" rows
//   // ==========================
//   const renderNotFound = (content) => (
//     <tr>
//       <td
//         colSpan={safeTableHeading.length}
//         className='text-secondary bg-white text-center'
//       >
//         <span className='py-4'>{content}</span>
//       </td>
//     </tr>
//   );

//   // ==========================
//   // Final table structure
//   // ==========================
//   const renderTable = () => (
//     <div className='w-100'>
//       <Table striped bordered hover>
//         <thead>
//           <tr>{renderTableHeadings()}</tr>
//         </thead>
//         <tbody>
//           {search_filter_loading ? (
//             <tr>
//               <td
//                 colSpan={safeTableHeading.length}
//                 className='text-secondary bg-white text-center'
//               >
//                 <Spinner animation='border' />
//               </td>
//             </tr>
//           ) : fetchError === '' && safeTableData.length > 0 ? (
//             renderTableData()
//           ) : fetchError ? (
//             renderNotFound(fetchError)
//           ) : (
//             renderNotFound(`NO ${tag.toUpperCase()} FOUND`)
//           )}
//         </tbody>
//       </Table>
//       {handleAddRow && (
//         <div className='text-center'>
//           <button onClick={() => handleAddRow(safeTableData.id)}>
//             <FontAwesomeIcon icon={faPlus} /> Add Row
//           </button>
//         </div>
//       )}
//     </div>
//   );

//   return <div className={styles.root__div}>{renderTable()}</div>;
// };

// export default DataTable;
import React from 'react';
import moment from 'moment';
import EditableCell from '../editableCell/EditableCelll.component';

import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';

import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './DataTable.module.scss';
import {
  faEdit,
  faMinus,
  faPlus,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

const DataTable = ({
  tableData,
  tableheading,
  handleOnClick,
  tag,
  editableCell,
  handleAddRow,
  handleRemoveRow,
  fetchError,
  search_filter_loading,
  renderTableDropdown,
  notFoundMessage,
  merchant,
  renderTerminalsDropdown,
}) => {
  // Define safe fallbacks for tableheading and tableData.
  const safeTableHeading =
    tableheading && tableheading.column ? tableheading.column : [];
  const safeTableData = Array.isArray(tableData) ? tableData : [];

  // Renders table headings using safeTableHeading
  const renderTableHeadings = () => {
    return safeTableHeading.map((data, index) => (
      <th key={index} className={styles.tableHead} align='center'>
        {data}
      </th>
    ));
  };

  // ==============================
  // Row-level render functions
  // ==============================

  const renderUploadHistory = (data) => (
    <tr onClick={() => handleOnClick(data.batchId)} key={data.batchId}>
      <td>{data.batchId}</td>
      <td>{data.merchantVoucherStore}</td>
      <td>{moment(data.createdDate).format('DD/MM/YYYY hh:mm A')}</td>
      <td>{data.status}</td>
      <td>{data.uploadedBy}</td>
    </tr>
  );

  const truncateString = (str, num) => {
    if (!str) return '';
    return str.length <= num ? str : str.substring(0, num) + '...';
  };

  const renderTransactionHistory = (data) => (
    <tr
      onClick={() => handleOnClick(data.redemptionId)}
      key={data.redemptionId}
    >
      <td>{moment(data.timestamp).format('DD/MM/YYYY hh:mm A')}</td>
      <td>{truncateString(data.description, 25)}</td>
      <td>{truncateString(data.amount?.toFixed(2).toString(), 10)}</td>
    </tr>
  );

  const renderUserDetails = (data) => (
    <tr key={data.email}>
      <td>{data.firstName}</td>
      <td>{data.lastName}</td>
      <td>{data.email}</td>
      <td>{data.mobileNo}</td>
    </tr>
  );

  const renderVoucherDetails = (data) => (
    <tr onClick={() => handleOnClick(data.id)} key={data.id}>
      <td>{data.id}</td>
      <td>{data.ownerName}</td>
      <td>{data.sender}</td>
      <td>
        {data.amount
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      </td>
      <td>{data.ownerMobile}</td>
    </tr>
  );

  const renderRedemptionsData = (data) => (
    <tr key={data.id}>
      <td>{data.id}</td>
      <td>
        {data.merchantEarning &&
          data.merchantEarning
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      </td>
    </tr>
  );

  const renderSubMerchantData = (data) => (
    <tr key={data.id}>
      <td>{data.id}</td>
      <td>{data.emails.join(', ')}</td>
      <td>{data.mobileNumber.join(', ')}</td>
      <td>{data.location}</td>
      <td style={{ width: '10px' }}>
        {renderTerminalsDropdown(data.terminals)}
      </td>
    </tr>
  );

  const renderMerchantData = (data) => (
    <tr
      onClick={() => handleOnClick(data.id)}
      style={{ cursor: 'pointer' }}
      key={data.id}
    >
      <td>{data.id}</td>
      <td>{data.name}</td>
      <td>{data.category}</td>
      <td>{data.status}</td>
      <td>{data.website}</td>
      <td>{data.tel}</td>
    </tr>
  );

  const renderTerminalData = (data) => (
    <tr onClick={() => handleOnClick(data.id)} key={data.id}>
      <td>{data.id}</td>
      <td>{data.merchant.name}</td>
      <td>{data.subMerchant.id}</td>
      <td>{data.lastActive}</td>
    </tr>
  );

  const renderPaymentData = (data) => (
    <tr onClick={() => handleOnClick(data.id)} key={data.id}>
      <td>{moment(data.createdTimestamp).format('DD/MM/YYYY hh:mm A')}</td>
      {!merchant && <td>{data.id}</td>}
      <td>{data.referenceCode}</td>
      {!merchant && <td>{data.coreMerchant.name}</td>}
      <td>
        <span className='d-flex justify-content-end w-50'>
          {data.amount
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        </span>
      </td>
    </tr>
  );

  const renderClientOrder = (data) => (
    <tr onClick={() => handleOnClick(data.clientOrderId)} key={data.id}>
      <td>{data.clientOrderId}</td>
      <td>{data.referenceNo}</td>
      <td>{moment(data.date).format('DD/MM/YYYY hh:mm A')}</td>
      <td>{data.client.clientName}</td>
      <td>{data.totalAmount}</td>
    </tr>
  );

  const renderClientOrderItems = (data) => {
    const handleSave = (field, newValue) => {
      console.log(`Updated ${field} to ${newValue} for item ID ${data.id}`);
    };

    return (
      <tr key={data.id}>
        <EditableCell
          value={data.amount && data.amount.toFixed(2)}
          onSave={(newValue) => handleSave('amount', newValue)}
        />
        <EditableCell
          value={data.qty}
          onSave={(newValue) => handleSave('qty', newValue)}
        />
        <td>
          <OverlayTrigger
            placement='right'
            overlay={<Tooltip>{'Remove Row'}</Tooltip>}
          >
            <button onClick={() => handleRemoveRow(data.id)}>
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </OverlayTrigger>
        </td>
      </tr>
    );
  };

  // Render for invoice details including a grand total row.
  const renderInvoiceDetails = (data) => (
    <tr key={data.id}>
      <td>LKR {data.amount} Multi-Store e-gift card</td>
      <td>{data.qty}</td>
      <td>{data.amount.toFixed(2)}</td>
      <td>{(data.qty * data.amount).toFixed(2)}</td>
    </tr>
  );

  // ==========================
  // Main switch to render rows
  // ==========================
  const renderTableData = () => {
    switch (tag) {
      case 'terminal':
        return safeTableData.map((data) => renderTerminalData(data));
      case 'payments':
        return safeTableData.map((data) => renderPaymentData(data));
      case 'merchants':
        return safeTableData.map((data) => renderMerchantData(data));
      case 'subMerchants':
        return safeTableData.map((data) => renderSubMerchantData(data));
      case 'redemptions':
        return safeTableData.map((data) => renderRedemptionsData(data));
      case 'userDetails':
        return safeTableData.map((data) => renderUserDetails(data));
      case 'voucherDetails':
        return safeTableData.map((data) => renderVoucherDetails(data));
      case 'denominationUploads':
        return safeTableData.map((data) => renderUploadHistory(data));
      case 'transactionDetails':
        return safeTableData.map((data) => renderTransactionHistory(data));
      case 'clientDetails':
        return safeTableData.map((data) => renderClientOrder(data));
      case 'clientOrderItems':
        return safeTableData.map((data) => renderClientOrderItems(data));
      case 'invoiceDetails': {
        const grandTotal = safeTableData.reduce(
          (acc, item) => acc + item.qty * item.amount,
          0
        );
        return (
          <>
            {safeTableData.map((data) => renderInvoiceDetails(data))}
            <tr>
              <td
                colSpan={3}
                style={{ textAlign: 'center', fontWeight: 'bold' }}
              >
                Grand Total
              </td>
              <td style={{ fontWeight: 'bold' }}>{grandTotal.toFixed(2)}</td>
            </tr>
          </>
        );
      }
      default:
        return null;
    }
  };

  // ==========================
  // Render "no data" or "error" rows
  // ==========================
  const renderNotFound = (content) => (
    <tr>
      <td
        colSpan={safeTableHeading.length}
        className='text-secondary bg-white text-center'
      >
        <span className='py-4'>{content}</span>
      </td>
    </tr>
  );

  // ==========================
  // Final table structure
  // ==========================
  const renderTable = () => (
    <div className='w-100'>
      <Table striped bordered hover>
        <thead>
          <tr>{renderTableHeadings()}</tr>
        </thead>
        <tbody>
          {search_filter_loading ? (
            <tr>
              <td
                colSpan={safeTableHeading.length}
                className='text-secondary bg-white text-center'
              >
                <Spinner animation='border' />
              </td>
            </tr>
          ) : fetchError === '' && safeTableData.length > 0 ? (
            renderTableData()
          ) : fetchError ? (
            renderNotFound(fetchError)
          ) : (
            renderNotFound(`NO ${tag.toUpperCase()} FOUND`)
          )}
        </tbody>
      </Table>
      {handleAddRow && (
        <div className='text-center'>
          <button onClick={() => handleAddRow(safeTableData.id)}>
            <FontAwesomeIcon icon={faPlus} /> Add Row
          </button>
        </div>
      )}
    </div>
  );

  return <div className={styles.root__div}>{renderTable()}</div>;
};

export default DataTable;
