import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import styles from './VoucherValueCard.module.scss';
import OrderDetailsModal from '../Modals/OrderDetailsModal';
import { ButtonToolbar } from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardCheck, faCopy } from '@fortawesome/free-solid-svg-icons';

class VoucherValueCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      selectedVoucherValue: null,
      csvData: [],
      filteredCsvData: [],
      copyApplied: false,
    };
  }

  componentDidMount() {
    this.readCsvFile();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.csvFile !== this.props.csvFile) {
      this.readCsvFile();
    }
  }

  /**
   * Reads the CSV file passed as a prop and parses it into an array of objects.
   */
  readCsvFile = () => {
    const { csvFile } = this.props;
    if (!csvFile) return;
    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target.result;
      const lines = text
        .split('\n')
        .map((line) => line.trim())
        .filter((line) => line.length > 0);
      if (lines.length > 1) {
        const headers = lines[0].split(',').map((header) => header.trim());
        const data = lines.slice(1).map((line) => {
          const values = line.split(',');
          const row = {};
          headers.forEach((header, index) => {
            row[header] = values[index] ? values[index].trim() : '';
          });
          return row;
        });
        this.setState({ csvData: data }, () => {
          if (this.props.onCsvDataLoaded) {
            this.props.onCsvDataLoaded(data);
          }
        });
      }
    };
    reader.readAsText(csvFile);
  };

  handleInputChange = (index, field, value) => {
    this.props.onUpdateByInput(index, field, value);
    // If the first voucher details change, allow re-copying
    if (index === 0) {
      this.setState({ copyApplied: false });
    }
  };

  handleCheckChange = (index, field, isChecked) => {
    this.props.onUpdateByCheck(index, field, isChecked);
  };

  /**
   * Copies the detail fields from the first voucher card to all other cards.
   */
  handleCopyForAll = () => {
    const { voucherValues, onUpdateByInput, onUpdateByCheck } = this.props;
    if (!voucherValues || voucherValues.length < 2) return;
    const source = voucherValues[0];
    voucherValues.forEach((voucher, index) => {
      if (index === 0) return;
      if (onUpdateByInput) {
        onUpdateByInput(index, 'deliveryType', source.deliveryType || '');
        onUpdateByInput(index, 'packagingType', source.packagingType || '');
        onUpdateByInput(
          index,
          'brandingMaterial',
          source.brandingMaterial || ''
        );
        onUpdateByInput(index, 'voucherMessage', source.voucherMessage || '');
        if (source.packagingTypeId) {
          onUpdateByInput(index, 'packagingTypeId', source.packagingTypeId);
        }
      }
      if (onUpdateByCheck) {
        onUpdateByCheck(index, 'separateBag', !!source.separateBag);
        onUpdateByCheck(index, 'coBranded', !!source.coBranded);
      }
    });
    // Set the flag to indicate that details have been copied
    this.setState({ copyApplied: true });
  };

  handleShowVoucherValueDetails = (voucher) => {
    const { csvData } = this.state;
    const filteredCsvData = csvData.filter(
      (row) => row['Voucher Value*'] === voucher.amount.toString()
    );
    this.setState({
      selectedVoucherValue: voucher,
      showModal: true,
      filteredCsvData: filteredCsvData,
    });
  };

  handleCloseVoucherValueDetails = () => {
    this.setState({
      showModal: false,
      selectedVoucherValue: null,
      filteredCsvData: [],
    });
  };

  render() {
    const { voucherValues, onRemove, packagingTypes } = this.props;
    const { showModal, selectedVoucherValue, filteredCsvData } = this.state;

    return (
      <div className={styles.voucherCardContainer}>
        {voucherValues.length > 0 ? (
          voucherValues.map((voucher, index) => (
            <Card key={index} className={styles.voucherCard}>
              <Card.Header className={styles.cardHeader}>
                <p className={styles.cardTitle}>
                  {voucher.amount} Voucher Value
                </p>
              </Card.Header>
              <button
                type='button'
                className={styles.closeButton}
                onClick={() => onRemove(index)}
                aria-label='Remove voucher'
              >
                ✖
              </button>
              <Card.Body className={styles.cardBody}>
                <Form>
                  {/* First Row: Value, Qty, Voucher Type */}
                  <Form.Group
                    controlId={`row1-${index}`}
                    className={styles.rowGroup}
                  >
                    <div className={styles.inputGroup}>
                      <Form.Label>Value</Form.Label>
                      <Form.Control
                        type='text'
                        value={voucher.amount}
                        readOnly={voucher.voucherType === 'INDIVIDUAL'}
                        onChange={(e) =>
                          this.handleInputChange(
                            index,
                            'amount',
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div className={styles.inputGroup}>
                      <Form.Label>Qty</Form.Label>
                      <Form.Control
                        type='number'
                        value={voucher.quantity}
                        readOnly={voucher.voucherType === 'INDIVIDUAL'}
                        onChange={(e) =>
                          this.handleInputChange(
                            index,
                            'quantity',
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div className={styles.inputGroup}>
                      <Form.Label>Voucher Type</Form.Label>
                      <Form.Control
                        type='text'
                        value={voucher.voucherType}
                        readOnly
                        onChange={(e) =>
                          this.handleInputChange(
                            index,
                            'voucherType',
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </Form.Group>

                  {/* Details Section: Delivery Type to Voucher Message */}
                  <div className={styles.detailsSection}>
                    {index === 0 &&
                      (() => {
                        const disableCopyAll =
                          voucherValues.length === 1 ||
                          !voucherValues[0].deliveryType ||
                          !voucherValues[0].packagingType ||
                          !voucherValues[0].brandingMaterial;
                        return (
                          <button
                            type='button'
                            onClick={
                              !disableCopyAll
                                ? this.handleCopyForAll
                                : undefined
                            }
                            className={styles.copyButton}
                            title={
                              this.state.copyApplied
                                ? 'Details have been applied'
                                : disableCopyAll
                                ? voucherValues.length === 1
                                  ? "'Copy for All' is disabled when there's only one voucher card"
                                  : 'Enter voucher value details to enable Copy for All'
                                : 'Copy these details to all voucher cards'
                            }
                            disabled={disableCopyAll}
                          >
                            <div className={styles.copyButtonContent}>
                              {this.state.copyApplied ? (
                                <FontAwesomeIcon
                                  icon={faClipboardCheck}
                                  style={{ color: '#56C729' }}
                                />
                              ) : (
                                <FontAwesomeIcon icon={faCopy} />
                              )}
                            </div>
                          </button>
                        );
                      })()}

                    <Form.Group
                      controlId={`row2-${index}`}
                      className={styles.rowGroup}
                    >
                      <div className={styles.inputGroup}>
                        <Form.Label>Delivery Type</Form.Label>
                        <Form.Control
                          as='select'
                          value={voucher.deliveryType || ''}
                          onChange={(e) =>
                            this.handleInputChange(
                              index,
                              'deliveryType',
                              e.target.value
                            )
                          }
                          isInvalid={!voucher.deliveryType}
                        >
                          <option value='' disabled>
                            Select Delivery Type
                          </option>
                          <option value='E_VOUCHER_ONLY'>E-Voucher</option>
                          <option value='PHYSICAL_ONLY'>Physical</option>
                          <option value='E_VOUCHER_AND_PHYSICAL'>
                            E-Voucher & Physical
                          </option>
                        </Form.Control>
                        <Form.Control.Feedback type='invalid'>
                          Please select a Delivery Type
                        </Form.Control.Feedback>
                      </div>
                      <div className={styles.inputGroup}>
                        <Form.Label>Packaging Type</Form.Label>
                        <Form.Control
                          as='select'
                          value={voucher.packagingType || ''}
                          onChange={(e) => {
                            const selectedPackaging = packagingTypes.find(
                              (type) => type.name === e.target.value
                            );
                            this.handleInputChange(
                              index,
                              'packagingType',
                              e.target.value
                            );
                            if (selectedPackaging) {
                              this.handleInputChange(
                                index,
                                'packagingTypeId',
                                selectedPackaging.id
                              );
                            }
                          }}
                          isInvalid={!voucher.packagingType}
                        >
                          <option value='' disabled>
                            Select Packaging Type
                          </option>
                          {packagingTypes.map((type) => (
                            <option key={type.id} value={type.name}>
                              {type.name}
                            </option>
                          ))}
                        </Form.Control>
                        <Form.Control.Feedback type='invalid'>
                          Please select a Packaging Type
                        </Form.Control.Feedback>
                      </div>
                      <div className={styles.inputGroup}>
                        <Form.Label>Branding Material</Form.Label>
                        <Form.Control
                          as='select'
                          value={voucher.brandingMaterial || ''}
                          onChange={(e) =>
                            this.handleInputChange(
                              index,
                              'brandingMaterial',
                              e.target.value
                            )
                          }
                          isInvalid={!voucher.brandingMaterial}
                        >
                          <option value='' disabled>
                            Select Branding Material
                          </option>
                          <option value='COME_UP_FROM_SCRATCH'>
                            Come Up From Scratch
                          </option>
                          <option value='CUSTOMER_GAVE_LOGO'>
                            Customer Gave Logo
                          </option>
                          <option value='CUSTOMER_GAVE_LOGO_AND_BRAND_REQUIREMENT'>
                            Customer Gave Logo + Brand Req
                          </option>
                        </Form.Control>
                        <Form.Control.Feedback type='invalid'>
                          Please select a Branding Material
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>

                    <Form.Group
                      controlId={`row3-${index}`}
                      className={styles.checkboxRowGroup}
                    >
                      <div className={styles.checkboxInput}>
                        <Form.Label>Separate Bag</Form.Label>
                        <Form.Check
                          type='checkbox'
                          id={`separateBag-${index}`}
                          checked={voucher.separateBag || false}
                          onChange={(e) =>
                            this.handleCheckChange(
                              index,
                              'separateBag',
                              e.target.checked
                            )
                          }
                        />
                      </div>
                      <div className={styles.checkboxInput}>
                        <Form.Label>Co-Branded Voucher</Form.Label>
                        <Form.Check
                          type='checkbox'
                          id={`coBranded-${index}`}
                          checked={voucher.coBranded || false}
                          onChange={(e) =>
                            this.handleCheckChange(
                              index,
                              'coBranded',
                              e.target.checked
                            )
                          }
                        />
                      </div>
                    </Form.Group>

                    <Form.Group
                      controlId={`row4-${index}`}
                      className={styles.rowGroup}
                    >
                      <div className={styles.inputGroup}>
                        <Form.Label>Voucher Message</Form.Label>
                        <Form.Control
                          as='textarea'
                          rows={4}
                          value={voucher.voucherMessage || ''}
                          onChange={(e) =>
                            this.handleInputChange(
                              index,
                              'voucherMessage',
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </Form.Group>
                  </div>

                  {voucher.voucherType === 'INDIVIDUAL' && (
                    <ButtonToolbar
                      appearance='link'
                      onClick={() =>
                        this.handleShowVoucherValueDetails(voucher)
                      }
                      style={{
                        marginTop: '30px',
                        marginBottom: '20px',
                        color: '#312e33',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                      }}
                      onMouseOver={(e) => (e.target.style.color = '#4C4550')}
                      onMouseOut={(e) => (e.target.style.color = '#312e33')}
                    >
                      See Order Details
                    </ButtonToolbar>
                  )}
                </Form>
              </Card.Body>
            </Card>
          ))
        ) : (
          <p>No Voucher Values Available</p>
        )}

        <OrderDetailsModal
          show={showModal}
          csvData={filteredCsvData}
          onHide={this.handleCloseVoucherValueDetails}
          voucher={selectedVoucherValue}
        />
      </div>
    );
  }
}

export default VoucherValueCard;
