import { connect } from 'react-redux';
import CheckableDataTable from '../../components/CheckableDataTable/CheckableDataTable';
import DataTable from '../../components/DataTable/DataTable';
import useComponentState from '../../utils/useComponentState';
import styles from '../ViewClientOrder/ViewClientOrder.module.scss';
import { AppButton, Loading, PageHeader, Pagination } from '../../components';
import { Button, CloseButton, OverlayTrigger, Tooltip } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import { Drawer } from 'rsuite';
import VoucherActivationModal from '../../components/Modals/VoucherActivationModal';
import VoucherExtensionModal from '../../components/Modals/VoucherExtensionModal';
import ViewMerchantViewModal from '../../components/Modals/MerchantCodesModal';
import React, { useEffect } from 'react';
import { getVouchersByStatus } from '../../api/Vouchers';
import { getClientOrders } from '../../api/clientOrder';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faEdit } from '@fortawesome/free-solid-svg-icons';
import { EarningsRoles } from '../../types/RedemptionEnums';
import UnredeemPopup from '../../components/unredeemPopup/UnredeemPopup.component';
import Form from 'react-bootstrap/Form';
import { getAllOffers, getOffersByMerchantName } from '../../api/offer';
import { getAllMerchant } from '../../api/merchant';
import dataTable from '../../components/DataTable/DataTable';

const ViewClientOrders = (props) => {
  const [state, setState] = useComponentState({
    tableHeading: {
      column: [
        'Order ID',
        'Reference No',
        'Order Date',
        'Client Name',
        'Total Amount',
      ],
    },
    orderItemTableHeading: {
      column: ['Amount', 'Qty', ''],
    },
    clientOrderData: [],
    pageSize: 30,
    currentPage: 0,
    totalPages: 0,
    loading: false,
    fetchError: '',
    selectAll: false,
    showClientOrderDrawer: false,
    drawerData: null,
    clientOrderId: '',
    selectedClientOrder: null,
    showUpdateButton: false,
    updateData: [],
    clientList: [],
    paginateList: [],
    selectedClient: '',
  });

  const {
    tableHeading,
    clientOrderData,
    pageSize,
    currentPage,
    loading,
    totalPages,
    fetchError,
    showClientOrderDrawer,
    drawerData,
    clientOrderId,
    selectedClientOrder,
    showUpdateButton,
    orderItemTableHeading,
    selectedClient,
    updatedData,
  } = state;

  useEffect(() => {
    getClientOrderDetails(0);
    getClients();
  }, []);

  const handleAddRow = () => {
    console.log(drawerData);

    const newRow = {
      id: Date.now(), // Generate a unique ID
      amount: 0, // Default amount value
      qty: 0, // Default quantity value
    };

    setState((prevState) => ({
      ...prevState,
      drawerData: {
        ...prevState.drawerData,
        orderItems: [...prevState.drawerData.orderItems, newRow],
      },
      showUpdateButton: true,
    }));

    console.log(drawerData);
  };

  const handleRemoveRow = (id) => {
    const updatedOrderItems = drawerData.orderItems.filter(
      (row) => row.id !== id
    );

    setState((prevState) => ({
      ...prevState,
      drawerData: {
        ...prevState.drawerData,
        orderItems: updatedOrderItems,
      },
      showUpdateButton: true,
    }));
  };

  const getClientOrderDetails = async (currentPage) => {
    setState({ loading: true, clientOrderData: [], fetchError: '' });
    try {
      const response = await getClientOrders(
        props.auth.auth.token,
        currentPage,
        pageSize
      );

      setState({
        clientOrderData: response.list,
        totalPages: response.totalPageCount,
        currentPage: response.pageNo,
      });
    } catch (error) {
      setState({
        fetchError: 'Oops, something went wrong. Please try again',
      });
    }
    setState({ loading: false });
  };

  const updateOnClick = () => {};

  const handleOnClick = (id) => {
    const rowData = clientOrderData.find((data) => data.clientOrderId === id);
    setState({
      showClientOrderDrawer: true,
      drawerData: rowData,
      clientOrderId: rowData.clientOrderId,
    });
  };

  const handleEditClick = () => {
    setState({ showUpdateButton: !showUpdateButton });
  };

  const handleClientOrderPagination = (page) => {
    getClientOrderDetails(page);

    setState({
      currentPage: page,
    });
  };

  const handleDrawerOnHide = () => {
    setState({
      showClientOrderDrawer: !showClientOrderDrawer,
      selectedClient: '',
      showUpdateButton: false,
    });
  };

  const renderClientDropDown = (data) => {
    return (
      <Form.Control
        as='select'
        onChange={(e) => handleClientDropDownOnChange(e.target.value)}
        // value={state.selectedClient}
        value={state.selectedClient}
      >
        <option>{data.client.clientName}</option>
        {state.clientList.map((item) => {
          return <option key={item.id}>{item.name}</option>;
        })}
      </Form.Control>
    );
  };

  const handleClientDropDownOnChange = (value) => {
    setState({ selectedClient: value });
  };

  const getClients = async () => {
    try {
      const response = await getAllMerchant(props.auth.auth.token);
      const sortList = sortClientList(response);
      setState({
        clientList: sortList,
      });
    } catch (err) {
      setState({ offersLoading: false, offersError: err.message });
    }
  };
  const sortClientList = (data) => {
    return data.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    );
  };

  const renderDrawer = () => {
    const data = drawerData;
    const clientOrderId = data.clientOrderId;

    return (
      <div className={styles.client_order_drawer_root}>
        <div className={styles.drawer_child}>
          <div className={styles.header_group}>
            <span className={styles.header_title}>CLIENT ORDER DETAILS</span>
          </div>
          {renderClientOrder(data)}
        </div>
      </div>
    );
  };

  const renderClientOrder = (data) => {
    return (
      <div className={styles.drawer__div}>
        <div>
          <h5 className={styles.client_order_header}>
            Order Id
            <OverlayTrigger
              placement={'top'}
              overlay={<Tooltip>{'Click to update'}</Tooltip>}
            >
              <Button
                onClick={handleEditClick}
                variant='outline-dark'
                size='sm'
                className={styles.copy_icon}
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
            </OverlayTrigger>
          </h5>

          <h5 className={styles.client_order_header}>
            {data.clientOrderId.toUpperCase()}
          </h5>
        </div>

        <div className={styles.drawer__child}>
          <div className={styles.details__horizontal}>
            <div className={styles.detail__group__nested}>
              <span className={styles.detail__header}>Ordered Date</span>
              <span className={styles.detail__value}>
                {moment(data.date)
                  .tz('Asia/Kolkata')
                  .format('DD/MM/YYYY hh:mm A')}{' '}
                (IST)
              </span>
            </div>
            <div className={styles.detail__group__nested}>
              <span className={styles.detail__header}>Reference No</span>
              <span className={styles.detail__value}>{data.referenceNo}</span>
            </div>
          </div>

          <div className={styles.details__horizontal}>
            <div className={styles.detail__group__nested}>
              <span className={styles.detail__header}>Total Amount</span>
              {showUpdateButton ? (
                <input
                  type='text'
                  value={data.totalAmount}
                  onChange={(e) =>
                    setState({
                      ...state,
                      drawerData: { ...data, totalAmount: e.target.value },
                    })
                  }
                  style={{
                    width: '100%',
                    // boxSizing: 'border-box',
                    padding: '5px',
                    fontSize: 'inherit',
                    border: '1px solid #ddd',
                    borderRadius: '4px',
                  }}
                />
              ) : (
                <span className={styles.detail__value}>{data.totalAmount}</span>
              )}
            </div>
            <div className={styles.detail__group__nested}>
              <span className={styles.detail__header}>Status</span>
              {showUpdateButton ? (
                <input
                  type='text'
                  value={data.status}
                  onChange={(e) =>
                    setState({
                      ...state,
                      drawerData: { ...data, status: e.target.value },
                    })
                  }
                  style={{
                    width: '100%',
                    // boxSizing: 'border-box',
                    padding: '5px',
                    fontSize: 'inherit',
                    border: '1px solid #ddd',
                    borderRadius: '4px',
                  }}
                />
              ) : (
                <span className={styles.detail__value}>{data.status}</span>
              )}
            </div>
          </div>
          <div className={styles.details__horizontal}>
            <div className={styles.detail__group__nested}>
              <span className={styles.detail__header}>
                Client ({data.client.id})
              </span>

              {showUpdateButton ? (
                renderClientDropDown(data)
              ) : (
                <span className={styles.detail__value}>
                  {data.client.clientName}
                </span>
              )}
            </div>
            <div className={styles.detail__group__nested}>
              <span className={styles.detail__header}>Sales Agent Email</span>
              {showUpdateButton ? (
                <input
                  type='text'
                  value={data.salesAgentEmail}
                  onChange={(e) =>
                    setState({
                      ...state,
                      drawerData: { ...data, salesAgentEmail: e.target.value },
                    })
                  }
                  // className={styles.detail__input}
                  style={{
                    width: '100%',
                    // boxSizing: 'border-box',
                    padding: '5px',
                    fontSize: 'inherit',
                    border: '1px solid #ddd',
                    borderRadius: '4px',
                  }}
                />
              ) : (
                <span className={styles.detail__value}>
                  {data.salesAgentEmail}
                </span>
              )}
            </div>
          </div>
          <div className={styles.details__horizontal}>
            <div className={styles.detail__group__nested}>
              <span className={styles.detail__header}>ContactPoint Name</span>
              {showUpdateButton ? (
                <input
                  type='text'
                  value={data.contactPointName}
                  onChange={(e) =>
                    setState({
                      ...state,
                      drawerData: { ...data, contactPointName: e.target.value },
                    })
                  }
                  style={{
                    width: '100%',
                    // boxSizing: 'border-box',
                    padding: '5px',
                    fontSize: 'inherit',
                    border: '1px solid #ddd',
                    borderRadius: '4px',
                  }}
                />
              ) : (
                <span className={styles.detail__value}>
                  {data?.contactPointName ?? ''}
                </span>
              )}
            </div>
            <div className={styles.detail__group__nested}>
              <span className={styles.detail__header}>ContactPoint Email</span>
              {showUpdateButton ? (
                <input
                  type='text'
                  value={data.contactPointEmail}
                  onChange={(e) =>
                    setState({
                      ...state,
                      drawerData: {
                        ...data,
                        contactPointEmail: e.target.value,
                      },
                    })
                  }
                  style={{
                    width: '100%',
                    // boxSizing: 'border-box',
                    padding: '5px',
                    fontSize: 'inherit',
                    border: '1px solid #ddd',
                    borderRadius: '4px',
                  }}
                />
              ) : (
                <span className={styles.detail__value}>
                  {data.contactPointEmail ?? ''}
                </span>
              )}
            </div>
          </div>

          {/*THIS IS THE ONE NOW I WANT TO ADD REMOVE ROWS*/}
          <DataTable
            tableData={data.orderItems}
            tableheading={orderItemTableHeading}
            handleOnClick={() => {}}
            tag='clientOrderItems'
            editableCell={true}
            handleAddRow={handleAddRow}
            handleRemoveRow={handleRemoveRow}
            fetchError=''
            searchFilterLoading={false}
            notFoundMessage={
              data.orderItems && data.orderItems.length === 0
                ? `No Order Items Found for ${data.clientOrderId}`
                : null
            }
          />

          <div style={{ display: 'flex' }}>
            {showUpdateButton && (
              <div style={{ flex: 1 }}>
                <AppButton
                  text='Update'
                  onClick={updateOnClick}
                  type='button'
                  loading={false}
                  containerStyle={{ marginTop: '10px' }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.root__div}>
      <div>
        <PageHeader text='Client Orders' />
        <div>
          <DataTable
            tableData={clientOrderData}
            tableheading={tableHeading}
            handleOnClick={handleOnClick}
            tag='clientDetails'
            fetchError={fetchError}
            notFoundMessage={fetchError}
            search_filter_loading={loading}
            searchFilterLoading={false}
          />
          {!loading && !fetchError && (
            <Pagination
              pageCount={totalPages}
              onChange={handleClientOrderPagination}
              currentPage={currentPage}
            />
          )}
        </div>
      </div>
      <Drawer show={showClientOrderDrawer} onHide={handleDrawerOnHide}>
        {drawerData && renderDrawer()}
      </Drawer>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(ViewClientOrders);
