import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const EditableCell = ({ value, onSave }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleDoubleClick = () => setIsEditing(true);
  const handleChange = (e) => setInputValue(e.target.value);
  const handleBlur = () => {
    setIsEditing(false);
    onSave(inputValue);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleBlur();
    }
  };

  return (
    <td onDoubleClick={handleDoubleClick}>
      {isEditing ? (
        <input
          type='text'
          value={inputValue}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyPress={handleKeyPress}
          autoFocus
          // style={{ width: '100%', padding: '5px' }}
          style={{
            width: '50%',
            // boxSizing: 'border-box',
            padding: '5px',
            fontSize: 'inherit',
            border: '1px solid #ddd',
            borderRadius: '4px',
          }}
        />
      ) : (
        <span>{inputValue}</span>
      )}
    </td>
  );
};

EditableCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onSave: PropTypes.func.isRequired,
};

export default EditableCell;
