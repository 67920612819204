import {
  DELETE,
  GET,
  POST,
  POST_FILE_AND_JSON_DATA,
  POST_FORM_DATA,
  PUT,
} from './core';

export const getEndpointWithPrefix = (endpoint) => {
  return `/client/${endpoint}`;
};

export const getAllClients = (token) => {
  const endpoint = getEndpointWithPrefix('');
  return GET(endpoint, token);
};

export const generateInvoice = (token, payload) => {
  const endpoint = getEndpointWithPrefix('download/order/invoice');
  return POST_FORM_DATA(endpoint, payload, token);
};

export const generateQuotation = (token, payload) => {
  const endpoint = getEndpointWithPrefix('download/order/quotation');
  return POST_FORM_DATA(endpoint, payload, token);
};

export const searchClientOrders = (token, searchTerm, page, pageSize) => {
  const endpoint = getEndpointWithPrefix(
    `order-search/${searchTerm}?page=${page}&pageSize=${pageSize}`
  );
  return GET(endpoint, token);
};

export const getSlips = (token, activeOrder) => {
  const endpoint = getEndpointWithPrefix(`get-upload-slip/${activeOrder}`);
  return GET(endpoint, token);
};

export const getVoucherThemes = (token) => {
  const endpoint = getEndpointWithPrefix('voucher-themes');
  return GET(endpoint, token);
}

export const updatePlaceOrder = (token, payload) => {
  const endpoint = getEndpointWithPrefix('update-order');
  return PUT(endpoint, payload, token);
};

export const uploadSlip = (token, activeOrder, payload) => {
  const endpoint = getEndpointWithPrefix(`upload-slip/${activeOrder}`);
  return POST_FORM_DATA(endpoint, payload, token);
};

export const approveOrSkipSlips = (token, payload, activeOrder) => {
  const endpoint = getEndpointWithPrefix(`slips/${activeOrder}`);
  return PUT(endpoint, payload, token);
};

export const createVoucher = (token, payload) => {
  const endpoint = getEndpointWithPrefix('voucher-initiate');
  return POST(endpoint, payload, token);
};

export const updateSlip = (token, payload) => {
  const endpoint = getEndpointWithPrefix('slip-update');
  return POST_FILE_AND_JSON_DATA(endpoint, payload, token, 'PUT');
};

export const deleteSlip = (token, payload) => {
  const endpoint = getEndpointWithPrefix('slip-delete');
  return DELETE(endpoint, payload, token);
};
