import React, { useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'react-phone-number-input/style.css';
import Spinner from 'react-bootstrap/Spinner';
import useComponentState from '../../utils/useComponentState';
import { reNotifyVoucher, getDeliveryEmail } from '../../api/Vouchers';

const VoucherRenotifyModal = (props) => {
  const { show, resendSuccess, resendLoading, voucherId, token, handleClose } =
    props;

  const [
    {
      viaSMS,
      viaEmail,
      receiverEmail,
      emailError,
      overrideEmail,
      newEmail,
      renotifyLoading,
      renotifySuccess,
      validated,
    },
    setState,
  ] = useComponentState({
    viaSMS: false,
    viaEmail: false,
    overrideEmail: false,
    receiverEmail: '',
    newEmail: '',
    emailError: '',
    renotifySuccess: false,
    renotifyLoading: false,
    validated: false,
  });

  useEffect(() => {
    if (voucherId) {
      fetchDeliveryEmail();
    }
  }, [voucherId]);

  const fetchDeliveryEmail = async () => {
    try {
      const { deliveryEmail } = await getDeliveryEmail(voucherId, token);
      setState({ receiverEmail: deliveryEmail });
    } catch (err) {}
  };

  const handleClear = () => {
    setState({
      viaSMS: false,
      viaEmail: false,
      overrideEmail: false,
      receiverEmail: '',
      newEmail: '',
      emailError: '',
      renotifySuccess: false,
      renotifyLoading: false,
      validated: false,
    });
    handleClose();
  };

  const handleReNotifyVoucher = async () => {
    if ((viaEmail && !receiverEmail) || overrideEmail) {
      const isValidEmail = validateEmail();
      if (!isValidEmail) return false;
    }
    setState({ validated: true, renotifyLoading: true });
    try {
      const request = {
        voucherId,
        destination: newEmail || receiverEmail,
        notifyViaSms: viaSMS,
        notifyViaEmail: viaEmail,
      };
      await reNotifyVoucher(request, token);
      setState({ renotifySuccess: true, renotifyLoading: false });
    } catch (error) {
      setState({ renotifySuccess: false, renotifyLoading: false });
    }
  };

  const handleCheck = (event) => {
    const { name, checked } = event.target;
    setState({ [name]: checked });
  };

  const handleEmailChange = (event) => {
    if (emailError) {
      setState({ emailError: '' });
    }
    const { value } = event.target;
    setState({ newEmail: value });
  };

  const handleOverride = (event) => {
    const { value } = event.target;
    setState({ overrideEmail: value === 'true' });
  };

  const validateEmail = () => {
    const pattern =
      /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,}[\.][a-z]{2,5}/g;
    const result = pattern.test(newEmail);

    if (!result) {
      setState({
        emailError: 'Enter a valid email address',
      });
    }

    return result;
  };

  return (
    <Modal
      show={show}
      style={{ opacity: 1 }}
      animation={false}
      centered
      onHide={() => handleClear()}
    >
      <Modal.Header closeButton>
        <Modal.Title>Re-Notify Receiver</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row style={{ paddingBottom: '1rem' }}>
          <Col sm>
            Renotify voucher <strong>{voucherId}</strong>
          </Col>
        </Row>

        {renotifySuccess ? (
          <Col sm>
            Voucher creation message for <strong>{voucherId}</strong> has been
            successfully resent to the receiver
          </Col>
        ) : (
          <>
            <Row>
              <Col sm>
                <Form.Group>
                  <Form.Check
                    reverse
                    checked={viaSMS}
                    onChange={handleCheck}
                    type='checkbox'
                    name='viaSMS'
                    readOnly
                    label={`via SMS`}
                  />
                </Form.Group>
              </Col>
              <Col sm>
                <Form.Group>
                  <Form.Check
                    reverse
                    checked={viaEmail}
                    onChange={handleCheck}
                    type='checkbox'
                    name='viaEmail'
                    readOnly
                    label={`via Email`}
                  />
                </Form.Group>
              </Col>
            </Row>
            {viaEmail && (
              <div
                style={{
                  border: '1px solid #ccc',
                  padding: '10px',
                  borderRadius: '5px',
                }}
              >
                {receiverEmail && (
                  <>
                    <Col sm>
                      <Form.Group>
                        <Form.Check
                          checked={overrideEmail == false}
                          onChange={handleOverride}
                          type='radio'
                          name='overrideEmail'
                          value={'false'}
                          label={`Re-notify ${receiverEmail}`}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm>
                      <Form.Group>
                        <Form.Check
                          checked={overrideEmail == true}
                          onChange={handleOverride}
                          type='radio'
                          name='overrideEmail'
                          value={'true'}
                          label={`Re-notify a different email instead`}
                        />
                      </Form.Group>
                    </Col>
                  </>
                )}
                {(!receiverEmail || overrideEmail) && (
                  <Col>
                    <Form.Group controlId='formBasicEmail'>
                      <Form.Label>Enter email address</Form.Label>
                      <Form.Control
                        value={newEmail}
                        onChange={handleEmailChange}
                        type='email'
                        placeholder='Enter email'
                      />
                    </Form.Group>
                    <small className='text-danger'>{emailError}</small>
                  </Col>
                )}
              </div>
            )}
            {validated && !renotifySuccess && !renotifyLoading && (
              <small className='text-center text-danger'>
                Oops, something went wrong!
              </small>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={() => handleClear()}>
          {renotifySuccess ? 'Close' : 'Cancel'}
        </Button>
        {!resendSuccess && (
          <Button
            variant='primary'
            onClick={() => handleReNotifyVoucher()}
            disabled={renotifyLoading}
            style={{ width: '90px' }}
          >
            {resendLoading ? (
              <Spinner size='sm' animation='border' />
            ) : (
              'Renotify'
            )}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default VoucherRenotifyModal;
