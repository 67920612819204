import React from 'react';
import { connect } from 'react-redux';
import styles from './GenerateInvoice.module.scss';
import { PageHeader } from '../../components';
import {
  ButtonGroup,
  Button,
  Whisper,
  Popover,
  Dropdown,
  IconButton,
  Loader,
} from 'rsuite';
import ArrowDownIcon from '@rsuite/icons/ArrowDown';
import { generateInvoice, generateQuotation } from '../../api/Client';
import useComponentState from '../../utils/useComponentState';
import DataTable from '../DataTable/DataTable';
import { toast } from 'react-toastify';

const options = ['Print Invoice', 'Print Quotation'];

const GenerateInvoice = (props) => {
  const [state, setState] = useComponentState({
    selectedIndex: 0,
    loading: false,
    tableHeading: {
      column: [
        'Description',
        'Quantity',
        'Unit Price(LKR)',
        'Total Price(LKR)',
      ],
    },
  });

  const displayToast = (message, type) => {
    if (type === 'success') {
      toast.success(message);
    } else if (type === 'error') {
      toast.error(message);
    }
  };

  // Update the selected option
  const handleOptionChange = (eventKey) => {
    setState({ selectedIndex: eventKey });
  };

  // Generate the invoice or quotation with a spinner while processing
  // Generate the invoice or quotation with a spinner while processing
  const handleGenerate = async () => {
    const payload = {
      clientOrderId: props.activeOrder.clientOrderId,
    };

    setState({ loading: true });
    try {
      let blob;
      let fileName;
      if (options[state.selectedIndex] === 'Print Invoice') {
        blob = await generateInvoice(props.auth.auth.token, payload);
        fileName = `${props.activeOrder.clientOrderId} - invoice.pdf`;
      } else if (options[state.selectedIndex] === 'Print Quotation') {
        blob = await generateQuotation(props.auth.auth.token, payload);
        fileName = `${props.activeOrder.clientOrderId} - quotation.pdf`;
      }

      // Save the file (using showSaveFilePicker or fallback)
      if (window.showSaveFilePicker) {
        const pickerOptions = {
          suggestedName: fileName,
          types: [
            {
              description: 'PDF Files',
              accept: { 'application/pdf': ['.pdf'] },
            },
          ],
        };
        const fileHandle = await window.showSaveFilePicker(pickerOptions);
        const writableStream = await fileHandle.createWritable();
        await writableStream.write(blob);
        await writableStream.close();
      } else {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }

      // Refresh the active order after generation, if applicable
      if (props.refreshOrdersAfterInvoiceGeneration) {
        await props.refreshOrdersAfterInvoiceGeneration(
          props.activeOrder.clientOrderId
        );
      }

      // Display a success toast message
      if (options[state.selectedIndex] === 'Print Invoice') {
        displayToast('Invoice generated successfully!', 'success');
      } else {
        displayToast('Quotation generated successfully!', 'success');
      }
    } catch (error) {
      if (options[state.selectedIndex] === 'Print Invoice') {
        displayToast('Invoice generation failed!', 'error');
      } else {
        displayToast('Quotation generation failed !', 'error');
      }
    } finally {
      // Stop the spinner
      setState({ loading: false });
    }
  };

  return (
    <div className={styles.div_root}>
      <PageHeader text='Generate Invoice' />

      <div className={styles.invoiceRoot}>
        <div className={styles.invoiceDetailTable}>
          <DataTable
            tableData={props.activeOrder.orderItems}
            tableheading={state.tableHeading}
            tag='invoiceDetails'
            fetchError=''
          />
        </div>

        <div className={styles.dropdownContainer}>
          <ButtonGroup className={styles.buttonGroup}>
            {/* Primary button triggers the generation; it shows a spinner while loading */}
            <Button
              appearance='primary'
              color='green'
              className={styles.button}
              onClick={handleGenerate}
              disabled={state.loading}
            >
              {state.loading ? (
                <Loader size='xs' inline content='Downloading' />
              ) : (
                options[state.selectedIndex]
              )}
            </Button>
            <Whisper
              placement='bottomEnd'
              trigger='click'
              speaker={({ onClose, left, top, className }, ref) => (
                <Popover
                  ref={ref}
                  className={`${className} ${styles.popover}`}
                  style={{ left, top }}
                  full
                >
                  <Dropdown.Menu
                    onSelect={(eventKey, event) => {
                      onClose();
                      handleOptionChange(eventKey);
                    }}
                  >
                    {options.map((item, index) => (
                      <Dropdown.Item key={index} eventKey={index}>
                        {item}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Popover>
              )}
            >
              <IconButton
                appearance='primary'
                color='green'
                icon={<ArrowDownIcon />}
                disabled={state.loading}
              />
            </Whisper>
          </ButtonGroup>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(GenerateInvoice);
