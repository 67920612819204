import { GET } from './core';

export const getEndpointWithPrefix = (endpoint) => {
  return `/products/${endpoint}`;
};

export const getVoucherThemes = (token, clientRef, page, limit) => {
  let endpoint = `all-products?featured=true&page=${page}&limit=${limit}`;
  if (clientRef) {
    endpoint += `&clientRef=${clientRef}`;
  }
  endpoint = getEndpointWithPrefix(endpoint);
  return GET(endpoint, token);
};
