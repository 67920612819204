import React, { useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useDropzone } from 'react-dropzone';
import useComponentState from '../../utils/useComponentState';
import { uploadDenominations } from '../../api/merchantVoucher';
import Spinner from 'react-bootstrap/Spinner';
import { saveAs } from 'file-saver';

import './BulkPurchaseModal.scss';

const MerchantVoucherUploadModal = ({ token, show, handleClose }) => {
  const [{ loading, uploadedFile, error, customClass, apiResponse }, setState] =
    useComponentState({
      loading: false,
      uploadedFile: null,
      error: '',
      customClass: '',
      apiResponse: {},
    });

  const handleModalClose = () => {
    handleClearError();
    handleClose();
  };

  const handleSubmit = async () => {
    handleClearError();
    if (acceptedFiles.length < 1 && fileRejections.length < 1) {
      setState({
        error: 'Please upload .csv to proceed',
        customClass: 'error',
      });
      return;
    }
    try {
      setState({ loading: true });
      await uploadDenominations(uploadedFile, token);
      setState({
        apiResponse: {
          message: 'Merchant voucher codes have been successfully added!',
          success: true,
        },
      });
    } catch (error) {
      console.log(error);
      setState({
        apiResponse: {
          message: 'Oops, something went wrong. Please try again',
          success: false,
        },
      });
    }
    setState({ loading: false });
  };

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    setState({ uploadedFile: acceptedFiles[0] });
    if (fileRejections.length > 0 && acceptedFiles.length < 1) {
      setState({
        error:
          'You can only upload *.csv files, and the max no of files is restricted to 1',
        customClass: 'error',
      });
    } else if (acceptedFiles.length > 0) {
      setState({ error: '', customClass: 'success', apiResponse: {} });
    }
  }, []);

  const {
    acceptedFiles,
    fileRejections,
    isDragActive,
    getRootProps,
    getInputProps,
  } = useDropzone({
    onDrop,

    maxFiles: 1,
    accept: { 'application/csv': ['.csv'] },
  });

  const files = acceptedFiles.map((file) => (
    <div key={file.path} className='pt-1'>
      {file.path}
    </div>
  ));

  const handleClearError = () => {
    setState({
      error: '',
      customClass: '',
      apiResponse: {},
    });
  };

  const downloadMerchantTemplate = () => {
    try {
      const filePath = '/files/bulkMerchant.csv';
      fetch(filePath)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Failed to fetch the template file');
          }
          return response.blob();
        })
        .then((blob) => {
          saveAs(blob, 'bulkMerchant.csv');
        });
    } catch (error) {
      console.error('Unexpected error:', error);
      setState({
        apiResponse: {
          message: 'Unexpected error occurred. Please try again later.',
          success: false,
        },
      });
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => handleModalClose()}
      backdrop='static'
      size='md'
      centered
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Upload Merchant Denominations</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='download-template'>
          <Button variant='link' size='md' onClick={downloadMerchantTemplate}>
            Download Template
          </Button>
        </div>
        <div {...getRootProps()} className={`dropzone__custom ${customClass}`}>
          <input {...getInputProps()} onClick={() => handleClearError()} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <p>Drag 'n' drop your .csv file, or click to select files</p>
          )}
        </div>
        <aside
          style={{
            color: error && acceptedFiles.length < 1 ? 'red' : '#208637',
            textAlign: error && acceptedFiles.length < 1 ? 'center' : '',
          }}
        >
          {error && acceptedFiles.length < 1 ? <small>{error}</small> : files}
        </aside>
        <div style={{ paddingTop: '1rem' }}>
          <Button
            variant='dark'
            block
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? <Spinner animation='border' /> : 'Upload Denominations'}
          </Button>
          <div className='text-center'>
            <small
              className={
                apiResponse && apiResponse.success
                  ? 'text-success'
                  : 'text-danger'
              }
            >
              {apiResponse.message}
            </small>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MerchantVoucherUploadModal;
