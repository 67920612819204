import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './BulkPurchaseModal.scss';
import { Form } from 'react-bootstrap';

const DatePickerModal = ({ show, onClose, onSave }) => {
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [notifyToUser, setNotifyToUser] = useState(false);

  // Set current date and time as initial values
  useEffect(() => {
    if (show) {
      const today = new Date();
      const formattedDate = today.toISOString().split('T')[0]; // Format as YYYY-MM-DD
      setSelectedDate(formattedDate);

      const hours = today.getHours().toString().padStart(2, '0');
      const minutes = today.getMinutes().toString().padStart(2, '0');
      const formattedTime = `${hours}:${minutes}`;
      setSelectedTime(formattedTime);
    }
  }, [show]);

  const handleSave = () => {
    onSave(selectedDate, selectedTime, notifyToUser);
    setNotifyToUser(false);
    onClose();
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      backdrop='static'
      size='md'
      centered
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Select Date and Time</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Pick a Date</Form.Label>
            <Form.Control
              type='date'
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
            />
          </Form.Group>
          <Form.Group className='mt-3'>
            <Form.Label>Pick a Time</Form.Label>
            <Form.Control
              type='time'
              value={selectedTime}
              onChange={(e) => setSelectedTime(e.target.value)}
            />
          </Form.Group>
          <Form.Group className='mt-3'>
            <Form.Check
              type='checkbox'
              label='Send SMS Notification to Customer'
              checked={notifyToUser}
              onChange={(e) => setNotifyToUser(e.target.checked)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onClose}>
          Cancel
        </Button>
        <Button variant='primary' onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DatePickerModal;
