export const APP_PARAMS = {
  DELIVERY_TYPE: 'deliveryType',
  DELIVERY_STATUS: 'deliveryStatus',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  SELECTED: 'selected',
  SEARCH_ID: 'searchId',
  SEARCH_KEY: 'searchKey',
  FILTER: 'filter',
  DRAWER: 'drawer',
  SORT: 'sort',
  PAGE: 'page',
};
