import { GET, POST_FILES } from './core';

export const getEndpointWithPrefix = (endpoint) => {
  return `/voucher/${endpoint}`;
};

export const getDenominationsSummary = (token) => {
  const endpoint = getEndpointWithPrefix('getAllMerchantVoucherDenominations');
  return GET(endpoint, token);
};

export const getDenominationsUploadSummary = (token) => {
  const endpoint = getEndpointWithPrefix('getAllMerchantVoucherBatchCode');
  return GET(endpoint, token);
};

export const uploadDenominations = (uploadedFile, token) => {
  const endpoint = getEndpointWithPrefix('upload/voucher-code-csv');
  return POST_FILES(endpoint, uploadedFile, token);
};
