import React, { useCallback, useEffect, useRef } from 'react';
import styles from './UploadSlip.module.scss';
import PageHeader from '../pageHeader/PageHeader.component';
import { useDropzone } from 'react-dropzone';
import useComponentState from '../../utils/useComponentState';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getSlips, uploadSlip, deleteSlip, updateSlip } from '../../api/Client';
import { connect } from 'react-redux';
import { Checkbox } from 'rsuite';
import { Dropdown } from 'react-bootstrap';
import { Button } from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';

const UploadSlip = (props) => {
  const [state, setState] = useComponentState({
    uploadedFile: null,
    error: '',
    uploadSuccess: '',
    fileUrl: '',
    previewVisible: false,
    slips: [],
    selectedSlip: null,
    isFullPayment: false,
    paymentType: '',
  });

  const editingSlipRef = useRef(null);

  // Fetch active slips from backend
  const fetchActiveSlips = async () => {
    try {
      const response = await getSlips(
        props.auth.auth.token,
        props.activeOrder.clientOrderId
      );
      setState((prev) => ({ ...prev, slips: response.uploadSlips }));
    } catch (error) {
      console.error('Error fetching slips:', error);
    }
  };

  // Fetch slips when component mounts or activeOrder changes
  useEffect(() => {
    setState((prev) => ({
      ...prev,
      slips: [],
      uploadedFile: null,
      uploadSuccess: '',
    }));
    if (props.activeOrder) {
      fetchActiveSlips();
    }
  }, [props.activeOrder]);

  const displayToast = (message, type) => {
    if (type === 'success') {
      toast.success(message);
    } else if (type === 'error') {
      toast.error(message);
    }
  };

  const showError = (message) => {
    setState((prev) => ({ ...prev, error: message }));
    displayToast(message, 'error');
  };

  // Updated onDrop to handle both new uploads and editing
  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length === 0) {
        showError(
          'Only PDF, PNG, or JPG files are allowed. Please upload a valid file.'
        );
        setState((prev) => ({
          ...prev,
          uploadedFile: null,
          fileUrl: '',
          previewVisible: false,
        }));
        return;
      }
      const file = acceptedFiles[0];
      if (file.type === 'application/pdf' || file.type.startsWith('image/')) {
        const fileUrl = URL.createObjectURL(file);
        // If editing, call handleEditSlip immediately
        if (editingSlipRef.current) {
          handleEditSlip(editingSlipRef.current, file);
          editingSlipRef.current = null; // Reset editing ref
        } else {
          // Otherwise, update state for a new upload
          setState((prev) => ({
            ...prev,
            uploadedFile: file,
            error: '',
            fileUrl,
            previewVisible: false,
          }));
        }
      } else {
        showError('Unsupported file type.');
        setState((prev) => ({
          ...prev,
          uploadedFile: null,
          fileUrl: '',
          previewVisible: false,
        }));
      }
    },
    [setState]
  );

  // Get open() from useDropzone to trigger file dialog programmatically
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      'application/pdf': ['.pdf'],
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg', '.jpg'],
    },
  });

  const togglePreview = () => {
    setState((prev) => ({
      ...prev,
      previewVisible: !prev.previewVisible,
    }));
  };

  const handleCheckboxChange = (value, checked) => {
    setState((prev) => ({ ...prev, isFullPayment: checked }));
  };

  // Save slip for new uploads
  const handleSaveSlip = async () => {
    if (!state.paymentType) {
      showError('Please select a payment method before uploading the slip.');
      return;
    }
    if (!state.uploadedFile) {
      showError('No file to upload.');
      return;
    }
    const payload = {
      uploadFile: state.uploadedFile,
      isFullPayment: state.isFullPayment,
      paymentType: state.paymentType,
    };

    console.log('Uploading slip with payload:', payload);

    try {
      const response = await uploadSlip(
        props.auth.auth.token,
        props.activeOrder.clientOrderId,
        payload
      );
      if (response.message === 'Slip Uploaded Successfully') {
        displayToast('Slip uploaded successfully!', 'success');
        setState((prev) => ({
          ...prev,
          uploadedFile: null,
          previewVisible: true,
        }));
      }
      fetchActiveSlips();
      if (props.refreshOrdersAfterSlipUpload) {
        props.refreshOrdersAfterSlipUpload(props.activeOrder.clientOrderId);
      }
    } catch (error) {
      showError('Slip upload failed.');
    }
  };

  // When clicking edit, set the slip to edit and open the file dialog via dropzone
  const handleEditButtonClick = (slip) => {
    editingSlipRef.current = slip;
    open(); // Opens the file dialog with the same onDrop logic
  };

  // Handle editing a slip by calling updateSlip
  const handleEditSlip = async (slip, file) => {
    const payload = {
      clientOrderSlipUpdateRequest: {
        slipId: slip.id,
        isFullPayment: state.isFullPayment,
        clientOrderId: props.activeOrder.clientOrderId,
      },
      uploadFile: file,
    };

    try {
      const response = await updateSlip(props.auth.auth.token, payload);
      if (response.message === 'SLIP_UPDATE_SUCCESSFULLY') {
        displayToast('Slip updated successfully!', 'success');
        fetchActiveSlips();
      } else {
        displayToast('Failed to update slip', 'error');
      }
    } catch (error) {
      displayToast('Failed to update slip', 'error');
    }
  };

  // Delete slip handler
  const handleDeleteSlip = async (slipId) => {
    const payload = {
      slipId: slipId,
      clientOrderId: props.activeOrder.clientOrderId,
    };
    try {
      const response = await deleteSlip(props.auth.auth.token, payload);
      if (response.message === 'SLIP_DELETED_SUCCESSFULLY') {
        displayToast('Slip deleted successfully!', 'success');
        fetchActiveSlips();
      }
    } catch (error) {
      showError('Failed to delete slip');
    }
  };

  const handleSlipClick = (slip) => {
    setState((prev) => ({ ...prev, selectedSlip: slip }));
  };

  const handleSkip = () => {
    const updatedOrder = {
      ...props.activeOrder,
      paymentStatus: 'PAYMENT_SKIPPED',
    };
    if (props.updateProgress) {
      props.updateProgress(updatedOrder);

      if (props.setManualStep) {
        props.setManualStep('goToStep4');
      }
    }
  };

  return (
    <div className={styles.div_root}>
      <PageHeader text='Upload Slip' />

      {/* Dropzone for both new uploads and file selection for editing */}
      <div
        {...getRootProps()}
        className={`${styles.dropzone__custom} ${
          isDragActive ? styles.active : ''
        }`}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <p>Drag 'n' drop the slip or click to select a file</p>
        )}
      </div>

      {state.uploadedFile && <p>Selected file: {state.uploadedFile.name}</p>}

      {state.uploadSuccess && (
        <p className='text-success'>{state.uploadSuccess}</p>
      )}

      {state.uploadedFile && (
        <div className={styles.actionsContainer}>
          <div className={styles.buttonContainer}>
            <Dropdown className='w-100'>
              <Dropdown.Toggle
                variant='light'
                id='dropdown-payment-method'
                className='w-100'
              >
                {state.paymentType || 'Select Payment Method'}
              </Dropdown.Toggle>
              <Dropdown.Menu className='w-100'>
                <Dropdown.Item
                  onClick={() =>
                    setState((prev) => ({
                      ...prev,
                      paymentType: 'BANK_TRANSFER',
                    }))
                  }
                >
                  Bank Deposit
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    setState((prev) => ({
                      ...prev,
                      paymentType: 'CHEQUE',
                    }))
                  }
                >
                  Cheque
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    setState((prev) => ({
                      ...prev,
                      paymentType: 'CARD_PAYMENT',
                    }))
                  }
                >
                  Card Payment
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <button onClick={togglePreview} className={styles.previewButton}>
              {state.previewVisible ? 'Hide Preview' : 'Show Preview'}
            </button>

            <div className={styles.buttonWithCheckbox}>
              <label>Full Payment:</label>
              <Checkbox
                value={!state.isFullPayment}
                checked={state.isFullPayment}
                onChange={handleCheckboxChange}
              />
              <Button
                appearance='primary'
                color='green'
                onClick={handleSaveSlip}
                className={styles.uploadButton}
              >
                Upload Slip
              </Button>
            </div>
          </div>
          {state.previewVisible && (
            <div className={styles.previewContainer}>
              {state.uploadedFile.type === 'application/pdf' ? (
                <embed
                  src={state.fileUrl}
                  type='application/pdf'
                  width='100%'
                  height='500px'
                />
              ) : (
                <img
                  src={state.fileUrl}
                  alt='Preview'
                  style={{ maxWidth: '100%', height: 'auto' }}
                />
              )}
            </div>
          )}
        </div>
      )}

      {/* Active Slips List */}
      {state.slips && state.slips.length > 0 && (
        <div className={styles.slipsContainer}>
          <h5 className={styles.slipsTitle}>Uploaded Slips</h5>
          <div className={styles.slipsGrid}>
            {state.slips.map((slip) => (
              <div
                key={slip.id}
                className={styles.slipCard}
                onClick={() => handleSlipClick(slip)}
              >
                <p>
                  {slip.clientName} Slip - {slip.id}
                </p>
                <div className={styles.actionButtonContainer}>
                  <button
                    type='button'
                    className={styles.actionButton}
                    onClick={() => {
                      window.open(slip.slipUrl, '_self');
                    }}
                    aria-label='View slip'
                  >
                    <FontAwesomeIcon icon={faDownload} />
                  </button>
                  {/* Edit button now uses dropzone's open() */}
                  <button
                    type='button'
                    className={styles.actionButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditButtonClick(slip);
                    }}
                    aria-label='Edit slip'
                  >
                    <FontAwesomeIcon icon={faPen} />
                  </button>
                  <button
                    type='button'
                    className={styles.actionButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteSlip(slip.id);
                    }}
                    aria-label='Delete slip'
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      <div>
        {!state.uploadedFile && state.slips.length === 0 && (
          <Button
            variant='dark'
            size='lg'
            style={{ marginTop: '20px', width: '100%' }}
            onClick={handleSkip}
          >
            Skip This Step
          </Button>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(UploadSlip);
