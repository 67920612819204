import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Loading, PageHeader, Pagination } from '../../components';
import SearchByDate from '../../components/SearchByDate/SearchByDate.components';
import {
  getPurchases,
  searchPurchases,
  filterPurchases,
} from '../../api/Purchase';

import Card from 'react-bootstrap/Card';
import InputGroup from 'react-bootstrap/InputGroup';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Accordion from 'react-bootstrap/Accordion';

import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import moment from 'moment';
import { Drawer } from 'rsuite';

import './ViewPurchases.scss';
import { useGetQueryParams } from '../../hooks/useGetQueryParams';
import { useSetQueryParams } from '../../hooks/useSetQueryParams';
import { APP_PARAMS } from '../../constants/appParams';

class ViewPurchases extends Component {
  state = {
    loading: true,
    tabItems: {
      type: [
        { key: 'ALL', label: 'All' },
        { key: 'CREATE', label: 'Create' },
        { key: 'TOPUP', label: 'Top up' },
        { key: 'TRANSFER', label: 'Transfer' },
        { key: 'REGIFT', label: 'Regift' },
        { key: 'EXTRA', label: 'Extra merchant' },
      ],

      status: [
        { key: 'ALL', label: 'All' },
        { key: 'PENDING', label: 'Pending' },
        { key: 'COMPLETE', label: 'Complete' },
      ],
    },
    typeKey: 'ALL',
    statusKey: 'ALL',
    tableheading: {
      header: 'Purchases',
      column: ['ID', 'User ID', 'Voucher ID', 'Amount (LKR)', 'Type', 'Status'],
    },
    tableData: [],
    total: 1,
    current: 0,
    fetchError: '',
    notFoundMessage: null,
    searchDropDown: [
      { key: 'PURCHASE', label: 'Purchase ID' },
      { key: 'USER', label: 'User ID' },
      { key: 'VOUCHER', label: 'Voucher ID' },
    ],
    searchPlaceholder: 'Purchase ID',
    searchId: '',
    searchKey: 'PURCHASE',
    startDate: null,
    endDate: null,
    isSearchApiCalled: false,
    isFilterApiCalled: false,
    showPurchaseDrawer: false,
    drawerData: null,
    isClearable: false,
  };

  componentDidMount() {
    this.getPurchasesApi(0);
  }

  getPurchasesApi = async (page) => {
    try {
      this.setState({ loading: true });
      const request = {
        typeKey: this.state.typeKey,
        statusKey: this.state.statusKey,
        page,
      };
      const token = this.props.auth.token;
      const response = await getPurchases(request, token);

      this.setState({
        tableData: response.purchasesList,
        total: response.total,
        current: response.current,
        loading: false,
        notFoundMessage: this.getNotFoundMessage(response.purchasesList),
      });
    } catch (err) {
      this.setState({
        fetchError: 'Failed to fetch purchases',
        loading: false,
        tableData: [],
      });
    }
  };

  searchPurchasesApi = async (page) => {
    this.setState({
      loading: true,
      isFilterApiCalled: false,
    });
    try {
      const request = {
        searchKey: this.state.searchKey,
        searchId: this.state.searchId,
        purchaseType: this.state.typeKey,
        page,
      };
      const token = this.props.auth.token;
      const response = await searchPurchases(request, token);

      this.setState({
        tableData: response.purchasesList,
        total: response.total,
        current: response.current,
        loading: false,
        notFoundMessage: this.getNotFoundMessageForSearch(
          response.purchasesList
        ),
      });
    } catch (err) {
      const { searchKey, searchId } = this.state;
      this.setState({
        fetchError: 'Failed to fetch purchases',
        loading: false,
        tableData: [],
        total: 0,
        current: 0,
        notFoundMessage: this.setNotFoundMessageForSearch(searchKey, searchId),
      });
    }
  };

  filterPurchasesApi = async (page, start, end) => {
    this.setState({
      loading: true,
      isSearchApiCalled: false,
      isFilterApiCalled: true,
    });
    try {
      const request = {
        startDate: moment(start).startOf('day'),
        endDate: moment(end).endOf('day'),
        purchaseType: this.state.typeKey,
        page,
      };
      const token = this.props.auth.token;
      const response = await filterPurchases(request, token);

      this.setState({
        tableData: response.purchasesList,
        total: response.total,
        current: response.current,
        loading: false,
        notFoundMessage: this.getNotFoundMessageForFilter(
          response.purchasesList
        ),
      });
    } catch (err) {
      this.setState({
        fetchError: 'Failed to fetch purchases',
        loading: false,
        tableData: [],
      });
    }
  };

  getNotFoundMessageForSearch = (data) => {
    const { searchKey, searchId } = this.state;
    let notFoundMessage = null;
    if (data.length === 0) {
      notFoundMessage = this.setNotFoundMessageForSearch(searchKey, searchId);
    }
    return notFoundMessage;
  };

  setNotFoundMessageForSearch = (searchKey, id) => {
    let notFoundMessage = null;
    if (searchKey === 'USER') {
      notFoundMessage = `No purchases made by ${id}`;
    } else {
      notFoundMessage = `No purchases found for ${id}`;
    }
    return notFoundMessage;
  };

  getNotFoundMessageForFilter = (data) => {
    const { startDate, endDate } = this.state;
    const start = moment(startDate).format('DD/MM/YYYY');
    const end = moment(endDate).format('DD/MM/YYYY');
    let notFoundMessage = null;
    if (data.length === 0) {
      notFoundMessage = `No purchases made during ${start} - ${end}`;
    }
    return notFoundMessage;
  };

  getNotFoundMessage = (data) => {
    let notFoundMessage = null;
    if (data.length === 0) {
      const { typeKey, statusKey, tabItems } = this.state;
      const type = tabItems.type.filter((item) => item.key === typeKey);
      const status = tabItems.status.filter((item) => item.key === statusKey);

      if (typeKey !== 'ALL' && statusKey !== 'ALL') {
        notFoundMessage = `No purchases found for type : ${type[0].label} and status : ${status[0].label}`;
      } else if (typeKey === 'ALL' && statusKey !== 'ALL') {
        notFoundMessage = `No purchases found for status : ${status[0].label}`;
      } else if (typeKey !== 'ALL' && statusKey === 'ALL') {
        notFoundMessage = `No purchases found for type : ${type[0].label}`;
      } else {
        notFoundMessage = `No purchases found`;
      }
    }
    return notFoundMessage;
  };

  handlePagination = (page) => {
    if (this.state.isSearchApiCalled) {
      this.searchPurchasesApi(page);
    } else if (this.state.isFilterApiCalled) {
      this.filterPurchasesApi(page, this.state.startDate, this.state.endDate);
    } else {
      this.getPurchasesApi(page);
    }
  };

  handleIdSearch = () => {
    if (!this.state.isClearable) {
      const { searchId } = this.state;
      if (searchId) {
        this.setState({
          isSearchApiCalled: true,
          isFilterApiCalled: false,
          isClearable: true,
        });
        this.searchPurchasesApi(0);
      }
    } else {
      this.handleIdOnClear();
    }
  };

  handleIdOnClear = () => {
    this.setState({
      searchId: '',
      isSearchApiCalled: false,
      isClearable: false,
      notFoundMessage: null,
    });
    this.getPurchasesApi(0);
  };

  searchOnChange = (event) => {
    this.setState({
      searchId: event.target.value,
      isClearable: false,
    });
  };

  onChangeStartDate = (startDate) => {
    const { endDate } = this.state;
    this.setState({ startDate });
    if (startDate && endDate) {
      this.filterPurchasesApi(0, startDate, endDate);
    }
    if (!startDate && !endDate) {
      this.setState({ isFilterApiCalled: false });
      this.getPurchasesApi(0);
    }
  };

  onChangeEndDate = (endDate) => {
    const { startDate } = this.state;
    this.setState({ endDate });
    if (startDate && endDate) {
      this.filterPurchasesApi(0, startDate, endDate);
    }
    if (!startDate && !endDate) {
      this.setState({ isFilterApiCalled: false });
      this.getPurchasesApi(0);
    }
  };

  handleOnClick = (data) => {
    this.setState({
      showPurchaseDrawer: true,
      drawerData: data,
    });
  };

  handleDrawerOnHide = () => {
    this.setState({
      showPurchaseDrawer: !this.state.showPurchaseDrawer,
    });
  };

  handleTabOnClick = (key, type) => {
    if (type === 'type') {
      this.setState(
        {
          typeKey: key,
        },
        () => this.getPurchasesApi(0)
      );
    } else {
      this.setState(
        {
          statusKey: key,
        },
        () => this.getPurchasesApi(0)
      );
    }
  };

  handleSearchDropDownOnClick = (key, label) => {
    this.setState({
      searchPlaceholder: label,
      searchKey: key,
      searchId: '',
    });
    this.getPurchasesApi(0);
  };

  capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
  };

  getToFixed = (amount) => {
    return amount
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  renderTableData = () => {
    const { notFoundMessage, loading, tableheading, tableData } = this.state;
    return (
      <Card className='table_div'>
        <div>
          <Table responsive hover>
            <thead className='table_head'>
              {tableheading.column.map((item, index) => {
                return <th key={index}>{item}</th>;
              })}
            </thead>
            <tbody>
              {notFoundMessage ? (
                <td colSpan={6} className='text-center'>
                  {notFoundMessage}
                </td>
              ) : loading ? (
                <td colSpan={6} className='text-center'>
                  <Loading text='Loading' />
                </td>
              ) : (
                tableData.map((data) => {
                  return (
                    <tr
                      bordered={false}
                      className='table_row'
                      onClick={() => {
                        this.handleOnClick(data);
                      }}
                    >
                      <td>{data.id}</td>
                      <td>{data.createdBy ? data.createdBy.id : 'N/A'}</td>
                      <td>{data.voucher ? data.voucher.id : 'N/A'}</td>
                      <td>{data.amount && this.getToFixed(data.amount)}</td>
                      <td>{data.type}</td>
                      <td>{this.capitalize(data.status)}</td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </Table>
        </div>
      </Card>
    );
  };

  renderMultipleSearch = () => {
    return (
      <InputGroup className='mb-3'>
        <DropdownButton
          as={InputGroup.Prepend}
          variant='secondary'
          title={this.state.searchPlaceholder}
          id='input-group-dropdown-1'
        >
          {this.state.searchDropDown.map((item) => {
            const { key, label } = item;
            return (
              <Dropdown.Item
                key={key}
                onClick={() => this.handleSearchDropDownOnClick(key, label)}
              >
                {item.label}
              </Dropdown.Item>
            );
          })}
        </DropdownButton>
        <Form.Control
          placeholder={this.state.searchPlaceholder}
          value={this.state.searchId}
          onChange={this.searchOnChange}
          required
          maxLength={10}
        />
        <InputGroup.Append>
          <Button variant='secondary' onClick={this.handleIdSearch}>
            <FontAwesomeIcon
              icon={this.state.isClearable ? faTimes : faSearch}
            />
          </Button>
        </InputGroup.Append>
      </InputGroup>
    );
  };

  renderFilterByStatus = () => {
    const { statusKey, tabItems } = this.state;
    return (
      <Breadcrumb>
        {tabItems.status.map((item) => {
          const { key, label } = item;
          return (
            <Breadcrumb.Item
              key={key}
              active={key === statusKey}
              onClick={() => {
                this.handleTabOnClick(key, 'status');
              }}
            >
              {label}
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
    );
  };

  renderFilterBlockContents = () => {
    return this.state.tabItems.type.map((item) => {
      const { key, label } = item;
      return (
        <Card
          className={
            this.state.typeKey !== key ? 'filter_card' : 'filter_card_active'
          }
          id={key}
          onClick={() => {
            this.handleTabOnClick(key, 'type');
          }}
        >
          <Card.Body>
            <Card.Text>{label}</Card.Text>
          </Card.Body>
        </Card>
      );
    });
  };

  renderDrawer = () => {
    const data = this.state.drawerData;
    const payment = data && data.payment;
    const user = data && data.createdBy;
    const voucher = data && data.voucher;
    const primaryContent = [
      { label: 'User ID', value: data && user ? user.id : 'N/A' },
      { label: 'Voucher ID', value: data && voucher ? voucher.id : 'N/A' },
      { label: 'Type', value: data && data.type },
      { label: 'Status', value: data && this.capitalize(data.status) },
      { label: 'Amount(LKR)', value: data && this.getToFixed(data.amount) },
      {
        label: 'Created Date',
        value: data && moment(data.createdDate).format('DD/MM/YYYY hh:mm A'),
      },
      { label: 'Delivery Email', value: data && data.deliveryEmail },
      { label: 'Delivery SMS', value: data && data.deliverySMS },
    ];

    let paymentContent = [];
    if (payment) {
      paymentContent = [
        { label: 'Payee Name', value: data && payment.payeeName },
        {
          label: 'Payment Type',
          value: data && this.capitalize(payment.paymentStatus),
        },
        {
          label: 'Payment Time',
          value:
            data && moment(payment.paymentTime).format('DD/MM/YYYY hh:mm A'),
        },
        {
          label: 'Order Reference No',
          value: data && payment.orderReferenceNumber,
        },
      ];
    }

    return (
      <div className='purchase_drawer_root'>
        <div className='drawer_child'>
          <div className='header_group'>
            <span className='header_title'>Purchase ID</span>
            <span className='header_value'>{data && data.id}</span>
          </div>
          <div className='details_group'>
            <Accordion defaultActiveKey='0'>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant='link' eventKey='0'>
                    Details
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey='0'>
                  <Card.Body>
                    {primaryContent.map((item) => {
                      return (
                        <div className='detail_group_content'>
                          <span className='content_header'>{item.label}</span>
                          <span className='content_value'>
                            {item.value ? item.value : 'N/A'}
                          </span>
                        </div>
                      );
                    })}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {data && data.payment && (
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant='link' eventKey='1'>
                      Payment Details
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey='1'>
                    <Card.Body>
                      {paymentContent.map((item) => {
                        return (
                          <div className='detail_group_content'>
                            <span className='content_header'>{item.label}</span>
                            <span className='content_value'>
                              {item.value ? item.value : 'N/A'}
                            </span>
                          </div>
                        );
                      })}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              )}
            </Accordion>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { loading, startDate, endDate, total, current } = this.state;
    return (
      <div className='root__div'>
        {loading ? (
          <Loading text='Loading' />
        ) : (
          <div className='root__div_main'>
            <PageHeader text='Purchases' />

            <div className='filter_block'>
              {this.renderFilterBlockContents()}
            </div>

            <div className='filter_block_status'>
              <span>Filter by status</span>
              {this.renderFilterByStatus()}
            </div>

            <div className='search_area_block'>
              <div className='search_area'>{this.renderMultipleSearch()}</div>
              <SearchByDate
                onStartDateChange={this.onChangeStartDate}
                onEndDateChange={this.onChangeEndDate}
                startDate={startDate}
                endDate={endDate}
              />
              <div className='seperator' />
            </div>

            {this.renderTableData()}

            {total > 1 && (
              <Pagination
                pageCount={total}
                onChange={this.handlePagination}
                currentPage={current}
              />
            )}
          </div>
        )}
        <Drawer
          show={this.state.showPurchaseDrawer}
          onHide={this.handleDrawerOnHide}
        >
          {this.renderDrawer()}
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

export default connect(mapStateToProps)(ViewPurchases);
