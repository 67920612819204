import React, { useCallback, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import useComponentState from '../../utils/useComponentState';
import styles from './PlaceOrder.module.scss';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { PageHeader } from '../../components';
import ClientOrderUploadModal from '../../components/Modals/ClientOrderUploadModal';
import { useDropzone } from 'react-dropzone';
import { createPlaceOrder, getAllSalesAgents } from '../../api/clientOrder';
import Papa from 'papaparse';
import VoucherValueCard from '../../components/VoucherValueCard/VoucherValueCard';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, InputGroup, FormControl } from 'react-bootstrap';
import { getAllPackagingType } from '../../api/Vouchers';
import { formatPackagingName } from '../../utils/formatNames';
import { getAllClients } from '../../api/Client';
import { toast } from 'react-toastify';

const PlaceOrder = (props) => {
  const [state, setState] = useComponentState({
    clientId: '',
    clientName: '',
    salesAgentId: '',
    contactPointName: '',
    contactPointEmail: '',
    primaryFormValidated: false,
    selectedClient: '',
    selectedAgent: '',
    clientList: [],
    agentList: [],
    showModal: false,
    uploadedFile: null,
    csvData: [],
    error: '',
    reqError: '',
    uploadSuccess: '',
    loading: false,
    csvVoucherValues: [],
    manualVoucherValues: [],
    clientSearchTerm: '',
    agentSearchTerm: '',
  });

  const voucherListRef = useRef(null);

  useEffect(() => {
    getClients();
    getAgents();
    getPackagingTypes();
  }, []);

  useEffect(() => {
    if (voucherListRef.current) {
      const lastVoucher = voucherListRef.current.lastElementChild;
      if (lastVoucher) {
        lastVoucher.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    }
  }, [state.manualVoucherValues.length]);

  const handleNext = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    setState({ primaryFormValidated: true });
  };

  const sortClientsList = (data) => {
    return data.sort((a, b) =>
      a.clientName.toLowerCase() > b.clientName.toLowerCase() ? 1 : -1
    );
  };

  const sortAgentsList = (data) => {
    return data.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    );
  };

  const getClients = async () => {
    try {
      const response = await getAllClients(props.auth.auth.token);
      const sortedList = sortClientsList(response.list);
      setState({ clientList: sortedList });
    } catch (err) {
      setState({ offersLoading: false, offersError: err.message });
    }
  };

  const getAgents = async () => {
    try {
      const response = await getAllSalesAgents(props.auth.auth.token);
      const sortedList = sortAgentsList(response);
      setState({ agentList: sortedList });
    } catch (err) {
      setState({ offersLoading: false, offersError: err.message });
    }
  };

  const getPackagingTypes = async () => {
    try {
      const response = await getAllPackagingType(props.auth.auth.token);

      // Map over the response and transform each name
      const formattedPackagingTypes = response.map((item) => ({
        ...item,
        name: formatPackagingName(item.name),
      }));

      setState({ packagingTypes: formattedPackagingTypes });
    } catch (err) {
      setState({ offersLoading: false, offersError: err.message });
    }
  };

  const displayToast = (message, type) => {
    if (type === 'success') {
      toast.success(message);
    } else if (type === 'error') {
      toast.error(message);
    }
  };

  const renderClientDropDown = () => {
    const filteredClients = state.clientSearchTerm
      ? state.clientList.filter((client) =>
          client.clientName
            .toLowerCase()
            .startsWith(state.clientSearchTerm.toLowerCase())
        )
      : state.clientList;

    return (
      <Dropdown className='w-100'>
        <Dropdown.Toggle variant='light' id='client-dropdown' className='w-100'>
          {state.selectedClient || 'Select A Client'}
        </Dropdown.Toggle>
        <Dropdown.Menu className='w-100'>
          <div className='px-3 py-2'>
            <InputGroup>
              <FormControl
                placeholder='Search clients...'
                value={state.clientSearchTerm}
                onChange={(e) => setState({ clientSearchTerm: e.target.value })}
              />
            </InputGroup>
          </div>
          {filteredClients.length > 0 ? (
            filteredClients.map((client) => (
              <Dropdown.Item
                key={client.id}
                onClick={() =>
                  setState({
                    selectedClient: client.clientName,
                    clientId: client.id,
                    clientSearchTerm: '',
                  })
                }
              >
                {client.clientName}
              </Dropdown.Item>
            ))
          ) : (
            <Dropdown.Item disabled>No results found</Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const renderAgentDropDown = () => {
    const filteredAgents = state.agentSearchTerm
      ? state.agentList.filter((agent) =>
          agent.name
            .toLowerCase()
            .startsWith(state.agentSearchTerm.toLowerCase())
        )
      : state.agentList;

    return (
      <Dropdown className='w-100'>
        <Dropdown.Toggle variant='light' id='agent-dropdown' className='w-100'>
          {state.selectedAgent && state.selectedAgent.name
            ? state.selectedAgent.name
            : 'Select A Sales Agent'}
        </Dropdown.Toggle>
        <Dropdown.Menu className='w-100'>
          <div className='px-3 py-2'>
            <InputGroup>
              <FormControl
                placeholder='Search agents...'
                value={state.agentSearchTerm}
                onChange={(e) => setState({ agentSearchTerm: e.target.value })}
              />
            </InputGroup>
          </div>
          {filteredAgents.length > 0 ? (
            filteredAgents.map((agent) => (
              <Dropdown.Item
                key={agent.id}
                onClick={() =>
                  setState({
                    selectedAgent: agent,
                    salesAgentId: agent.id,
                    agentSearchTerm: '',
                  })
                }
              >
                {agent.name}
              </Dropdown.Item>
            ))
          ) : (
            <Dropdown.Item disabled>No results found</Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const handleShowUploadModal = () => {
    setState({ showModal: !state.showModal });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ [name]: value });
  };

  const renderInputField = (name, label, value, onChange, max, customInput) => {
    return (
      <Form.Group as={Row}>
        <Col>
          <Form.Label>{label}</Form.Label>
          {customInput ? (
            customInput
          ) : (
            <Form.Control
              name={name}
              value={value}
              onChange={onChange}
              maxLength={max}
              required={name !== 'commission'}
              placeholder={
                name === 'imgUrl'
                  ? 'https://example.com/sample_image.png'
                  : `Enter ${label.toLowerCase()}`
              }
            />
          )}
          <Form.Control.Feedback type='invalid' className={styles.error_msg}>
            {name === 'email'
              ? 'Email address cannot be empty'
              : `${label} cannot be empty`}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
    );
  };

  // Handle CSV drop
  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length === 0) {
      setState({
        error: 'Only CSV files are allowed. Please upload a valid file.',
        uploadSuccess: '',
      });
      return;
    }

    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = ({ target }) => {
      const csv = target.result;
      Papa.parse(csv, {
        header: true,
        skipEmptyLines: true,
        complete: (result) => {
          const voucherMap = {};
          result.data.forEach((row) => {
            const value = row['Voucher Value*'];
            if (value) {
              const numValue = parseFloat(value);
              voucherMap[numValue]
                ? (voucherMap[numValue] += 1)
                : (voucherMap[numValue] = 1);
            }
          });
          const newCsvVouchers = Object.entries(voucherMap).map(
            ([amount, quantity]) => ({
              amount: amount,
              quantity: quantity,
              voucherType: 'INDIVIDUAL',
              fromCsv: true,
            })
          );
          setState({
            uploadedFile: file,
            error: '',
            uploadSuccess: '',
            csvVoucherValues: newCsvVouchers,
            csvData: result.data,
          });
        },
        error: (err) => {
          setState({ error: 'Error parsing CSV file: ' + err.message });
        },
      });
    };
    reader.readAsText(file);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: { 'text/csv': ['.csv'] },
  });

  const handleRemoveCsvVoucher = (index) => {
    const updatedCsvVouchers = [...state.csvVoucherValues];
    updatedCsvVouchers.splice(index, 1);
    setState({ csvVoucherValues: updatedCsvVouchers });
  };

  const handleRemoveManualVoucher = (index) => {
    const updatedManualVouchers = [...state.manualVoucherValues];
    updatedManualVouchers.splice(index, 1);
    setState({ manualVoucherValues: updatedManualVouchers });
  };

  const handleUpdateCsvVoucherByInput = (index, field, value) => {
    const updatedCsvVouchers = [...state.csvVoucherValues];
    updatedCsvVouchers[index][field] = value;
    setState({ csvVoucherValues: updatedCsvVouchers });
  };

  const handleUpdateManualVoucherByInput = (index, field, value) => {
    const updatedManualVouchers = [...state.manualVoucherValues];
    updatedManualVouchers[index][field] = value;
    setState({ manualVoucherValues: updatedManualVouchers });
  };

  const handleUpdateCsvVoucherByCheck = (index, field, isChecked) => {
    const updatedCsvVouchers = [...state.csvVoucherValues];
    updatedCsvVouchers[index][field] = isChecked;
    setState({ csvVoucherValues: updatedCsvVouchers });
  };

  const handleUpdateManualVoucherByCheck = (index, field, isChecked) => {
    const updatedManualVouchers = [...state.manualVoucherValues];
    updatedManualVouchers[index][field] = isChecked;
    setState({ manualVoucherValues: updatedManualVouchers });
  };

  const formatDeliveryDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    if (isNaN(date.getTime())) {
      return '';
    }
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };

  const handlePlaceClientOrder = async () => {
    // Validation
    if (
      !state.clientId ||
      !state.selectedClient ||
      !state.selectedAgent ||
      !state.contactPointName ||
      !state.contactPointEmail
    ) {
      setState({
        error: 'Please complete all client details to placing an order.',
      });
      return;
    }
    if (
      state.csvVoucherValues.length === 0 &&
      state.manualVoucherValues.length === 0
    ) {
      setState({
        error:
          'Please add at least one voucher manually or via CSV before placing an order.',
      });
      return;
    }
    for (const voucher of [
      ...state.csvVoucherValues,
      ...state.manualVoucherValues,
    ]) {
      if (
        !voucher.deliveryType ||
        !voucher.packagingType ||
        !voucher.brandingMaterial
      ) {
        setState({
          error:
            'Please select Delivery Type, Packaging Type, and Branding Material for all vouchers.',
        });
        return;
      }
    }

    // Build payload
    const payload = {
      clientDetails: {
        clientId: parseFloat(state.clientId),
        clientName: state.selectedClient,
        salesAgentId: state.selectedAgent.id,
        contactPointName: state.contactPointName,
        contactPointEmail: state.contactPointEmail,
      },
      clientOrderItemQuantityDetailRequest: {
        detailsList: [
          ...state.csvVoucherValues,
          ...state.manualVoucherValues,
        ].map((voucher) => ({
          amount: voucher.amount ? parseFloat(voucher.amount) : null,
          qty: voucher.quantity ? parseInt(voucher.quantity, 10) : null,
          deliveryType: voucher.deliveryType?.trim() || null,
          packagingTypeId: voucher.packagingTypeId
            ? parseInt(voucher.packagingTypeId, 10)
            : null,
          coBranded: !!voucher.coBranded,
          brandingMaterial: voucher.brandingMaterial?.trim() || null,
          separateBag: !!voucher.separateBag,
          voucherMessage: voucher.voucherMessage?.trim() || null,
          voucherType: voucher.voucherType?.trim() || null,
        })),
      },
      clientOrderItemDetailControllerRequest: state.csvData.map((order) => ({
        No: order.No ? parseInt(order.No, 10) : null,
        receiverName: order['Receiver’s Name*']?.trim() || null,
        methodOf: order['Method of Delivery*']?.trim() || null,
        receiverMobile: order['Receiver’s Mobile Number*']
          ? parseInt(order['Receiver’s Mobile Number*'])
          : null,
        receiverEmail: order['Receiver’s Email Address']?.trim() || null,
        voucherMessage: order['Voucher Message']?.trim() || null,
        senderName: order["Sender's Name*"]?.trim() || null,
        voucherValue: order['Voucher Value*']
          ? parseFloat(order['Voucher Value*'])
          : null,
        eliveryDateTime: order['Delivery Date & time*']?.trim()
          ? formatDeliveryDateTime(order['Delivery Date & time*'])
          : null,
      })),
    };

    try {
      setState({ loading: true });
      const response = await createPlaceOrder(payload, props.auth.auth.token);

      // Create new order with all details from the response.
      const newOrder = {
        ...response,
        orderCode: response.orderId,
      };

      // Call updateProgress to update progress, add the new order to the list,
      // and set it as the active order (so that VoucherSideBar renders it).
      if (props.updateProgress) {
        props.updateProgress(newOrder);
      }

      displayToast('Order placed successfully', 'success');
      // Reset the form state after updating progress
      setState({
        reqError: '',
        loading: false,
        selectedClient: '',
        selectedAgent: '',
        contactPointName: '',
        contactPointEmail: '',
        uploadedFile: null,
        csvData: [],
        error: '',
        uploadSuccess: '',
        csvVoucherValues: [],
        manualVoucherValues: [],
      });
    } catch (err) {
      setState({
        reqError: 'Order Creation Failed. Please try again.',
        reqSuccess: '',
        loading: false,
      });
    }
  };

  const addNewVoucherValueCard = () => {
    const newVoucher = {
      amount: '',
      quantity: '',
      voucherType: 'SELF_ACTIVATION',
      deliveryType: '',
      packagingType: '',
      brandingMaterial: '',
      separateBag: false,
      coBranded: false,
      voucherMessage: '',
      fromCsv: false,
    };
    setState((prevState) => ({
      ...prevState,
      manualVoucherValues: [...prevState.manualVoucherValues, newVoucher],
    }));
  };

  return (
    <div className={styles.div_root}>
      <div>
        <PageHeader text='Place Client Order' />
      </div>

      <div>
        <Form
          noValidate
          validated={state.primaryFormValidated}
          onSubmit={handleNext}
          style={{ marginTop: '20px' }}
        >
          <Row>
            <Col>
              {renderInputField(
                'clientId',
                'Client Name',
                state.selectedClient,
                handleInputChange,
                255,
                renderClientDropDown()
              )}
            </Col>
            <Col>
              {renderInputField(
                'salesAgent',
                'Sales Agent',
                state.selectedAgent && state.selectedAgent.name
                  ? state.selectedAgent.name
                  : '',
                handleInputChange,
                320,
                renderAgentDropDown()
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              {renderInputField(
                'contactPointName',
                'Contact point name',
                state.contactPointName,
                handleInputChange,
                50
              )}
            </Col>
            <Col>
              {renderInputField(
                'contactPointEmail',
                'Contact point Email',
                state.contactPointEmail,
                handleInputChange,
                50
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <div
                {...getRootProps()}
                className={`${styles.dropzone__custom} ${
                  isDragActive ? styles.active : ''
                }`}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Drop the files here ...</p>
                ) : (
                  <p>
                    Drag 'n' drop a CSV file or click here to select a file to
                    extract voucher values
                  </p>
                )}
              </div>
              {state.uploadedFile && state.csvVoucherValues.length > 0 && (
                <p>Selected file: {state.uploadedFile.name}</p>
              )}
              {state.uploadSuccess && (
                <p className='text-success'>{state.uploadSuccess}</p>
              )}
            </Col>
          </Row>

          <div className={styles.orDivider}>
            <span>Or</span>
          </div>

          <div className={styles.addIconButtonContainer}>
            <div
              className={styles.buttonAndText}
              onClick={addNewVoucherValueCard}
            >
              <div className={styles.iconButton}>
                <FontAwesomeIcon icon={faPlus} className={styles.icon} />
              </div>
              <p className={styles.addVoucherLabel}>
                Add Voucher Values Manually
              </p>
            </div>
          </div>

          {(state.csvVoucherValues.length > 0 ||
            state.manualVoucherValues.length > 0) && (
            <hr className={styles.boldHr} />
          )}

          {state.csvVoucherValues.length > 0 && (
            <div style={{ marginTop: '20px' }} ref={voucherListRef}>
              <h5 style={{ marginTop: '20px', textAlign: 'center' }}>
                Extracted Voucher Values From Selected CSV File
              </h5>
              <VoucherValueCard
                csvFile={state.uploadedFile}
                voucherValues={state.csvVoucherValues}
                onRemove={handleRemoveCsvVoucher}
                onUpdateByInput={handleUpdateCsvVoucherByInput}
                onUpdateByCheck={handleUpdateCsvVoucherByCheck}
                packagingTypes={state.packagingTypes}
              />
            </div>
          )}
          {state.manualVoucherValues.length > 0 && (
            <div style={{ marginTop: '20px' }} ref={voucherListRef}>
              <h5 style={{ marginTop: '20px', textAlign: 'center' }}>
                Manually Added Voucher Values
              </h5>
              <VoucherValueCard
                csvFile={state.uploadedFile}
                voucherValues={state.manualVoucherValues}
                onRemove={handleRemoveManualVoucher}
                onUpdateByInput={handleUpdateManualVoucherByInput}
                onUpdateByCheck={handleUpdateManualVoucherByCheck}
                packagingTypes={state.packagingTypes}
              />
            </div>
          )}

          {state.error && <p className='text-danger'>{state.error}</p>}

          <div className='d-flex justify-content-center'>
            <Button
              variant='dark'
              size='lg'
              onClick={handlePlaceClientOrder}
              disabled={
                state.loading ||
                [...state.csvVoucherValues, ...state.manualVoucherValues].some(
                  (voucher) =>
                    !voucher.deliveryType ||
                    !voucher.packagingType ||
                    !voucher.brandingMaterial
                )
              }
              style={{ marginTop: '20px', width: '100%' }}
            >
              {state.loading ? 'Placing...' : 'Place Client Order'}
            </Button>
          </div>
        </Form>
      </div>

      <ClientOrderUploadModal
        token={props.auth.auth.token}
        show={state.showModal}
        handleClose={handleShowUploadModal}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PlaceOrder);
