import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import useComponentState from '../../utils/useComponentState';
import { updateOwnership } from '../../api/Vouchers';
import './BulkPurchaseModal.scss';
import PhoneInput from 'react-phone-number-input';

const TransferOwnershipModal = ({
  token,
  show,
  handleClose,
  voucherId,
  updatedData,
  drawerHide,
}) => {
  const [{ loading, error, apiResponse }, setState] = useComponentState({
    loading: false,
    error: '',
    apiResponse: {},
  });

  const [recipientName, setRecipientName] = useState('');
  const [recipientMobileNo, setRecipientMobileNo] = useState('');
  const [notifyToSender, setNotifyToSender] = useState(false);
  const [notifyToRecipient, setNotifyToRecipient] = useState(false);
  const [nameError, setNameError] = useState('');

  const handleModalClose = () => {
    handleClearError();
    handleClose();
  };

  const handleClearError = () => {
    setState({ error: '', apiResponse: {} });
    setRecipientName('');
    setRecipientMobileNo('');
    setNotifyToSender(false);
    setNotifyToRecipient(false);
    setNameError('');
  };

  const validateFields = () => {
    let isValid = true;

    if (!recipientName.trim()) {
      setNameError("Recipient's name cannot be empty.");
      isValid = false;
    } else {
      setNameError('');
    }

    return isValid;
  };

  const handleTransferOwnership = async () => {
    if (!validateFields()) {
      return;
    }

    setState({ loading: true });
    try {
      const request = {
        voucherId,
        recipientName,
        recipientMobileNo,
        notifyToSender,
        notifyToRecipient,
      };
      const response = await updateOwnership(request, token);

      if (response.message === 'VOUCHER_TRANSFERRED_SUCCESSFULLY') {
        setState({ apiResponse: response, loading: false });
        handleClearError();
        handleClose();
        updatedData();
        drawerHide();
      } else {
        setState({
          error: response.message || 'An error occurred. Please try again.',
          loading: false,
        });
      }
    } catch (err) {
      setState({
        error: 'An error occurred. Please try again.',
        loading: false,
      });
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleModalClose}
      backdrop='static'
      size='md'
      centered
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Details of New Owner</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className='form-group'>
            <label>VoucherId</label>
            <input
              type='text'
              className='form-control'
              value={voucherId}
              readOnly
            />
          </div>
          <div className='form-group'>
            <label>New Owner's Name</label>
            <input
              type='text'
              className='form-control'
              value={recipientName}
              onChange={(e) => setRecipientName(e.target.value)}
              placeholder="Enter new owner's Name"
            />
            {nameError && <small className='text-danger'>{nameError}</small>}
          </div>
          <div className='form-group'>
            <label>New Owner's Mobile No</label>

            <PhoneInput
              required
              maxLength={15}
              defaultCountry={'LK'}
              value={recipientMobileNo}
              placeholder="Enter new owner's Mobile No."
              onChange={(value) => {
                setRecipientMobileNo(value);
              }}
              className='form-control'
            />
          </div>
          <div className='form-group form-check'>
            <input
              type='checkbox'
              className='form-check-input'
              checked={notifyToSender}
              onChange={(e) => setNotifyToSender(e.target.checked)}
            />
            <label className='form-check-label'>Notify Sender</label>
          </div>
          <div className='form-group form-check'>
            <input
              type='checkbox'
              className='form-check-input'
              checked={notifyToRecipient}
              onChange={(e) => setNotifyToRecipient(e.target.checked)}
            />
            <label className='form-check-label'>Notify Recipient</label>
          </div>
        </form>
        <div style={{ paddingTop: '1rem' }}>
          <Button
            variant='dark'
            block
            onClick={handleTransferOwnership}
            disabled={loading}
          >
            {loading ? <Spinner animation='border' /> : 'Submit'}
          </Button>
          <div className='text-center'>
            <small
              className={
                apiResponse && apiResponse.success
                  ? 'text-success'
                  : 'text-danger'
              }
            >
              {apiResponse.message}
            </small>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TransferOwnershipModal;
