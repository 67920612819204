import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import styles from './VoucherValueCardToUpdate.module.scss';
import { ButtonToolbar } from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardCheck, faCopy } from '@fortawesome/free-solid-svg-icons';

class VoucherValueCardToUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copyApplied: false,
    };
  }

  handleInputChange = (index, field, value) => {
    this.props.onUpdateByInput(index, field, value);
    if (index === 0) {
      this.setState({ copyApplied: false });
    }
  };

  handleCheckChange = (index, field, isChecked) => {
    this.props.onUpdateByCheck(index, field, isChecked);
  };

  handleCopyForAll = () => {
    const { voucherValues, onUpdateByInput, onUpdateByCheck } = this.props;
    if (!voucherValues || voucherValues.length < 2) return;
    const source = voucherValues[0];
    voucherValues.forEach((voucher, index) => {
      if (index === 0) return;
      onUpdateByInput(index, 'deliveryType', source.deliveryType || '');
      onUpdateByInput(index, 'packagingType', source.packagingType || '');
      onUpdateByInput(index, 'brandingMaterial', source.brandingMaterial || '');
      onUpdateByInput(index, 'voucherMessage', source.voucherMessage || '');
      if (source.packagingTypeId) {
        onUpdateByInput(index, 'packagingTypeId', source.packagingTypeId);
      }
      onUpdateByCheck(index, 'separateBag', !!source.separateBag);
      onUpdateByCheck(index, 'coBranded', !!source.coBranded);
    });
    this.setState({ copyApplied: true });
  };

  render() {
    const { voucherValues, onRemove, packagingTypes } = this.props;
    const { copyApplied } = this.state;

    return (
      <div className={styles.voucherCardContainer}>
        {voucherValues.length > 0 ? (
          voucherValues.map((voucher, index) => (
            <Card key={index} className={styles.voucherCard}>
              <Card.Header className={styles.cardHeader}>
                <p className={styles.cardTitle}>
                  {voucher.amount} Voucher Value
                </p>
              </Card.Header>
              <button
                type='button'
                className={styles.closeButton}
                onClick={() => onRemove(index)}
                aria-label='Remove voucher'
              >
                ✖
              </button>
              <Card.Body className={styles.cardBody}>
                <Form>
                  {/* Row 1: Value, Qty, Voucher Type */}
                  <Form.Group
                    controlId={`row1-${index}`}
                    className={styles.rowGroup}
                  >
                    <div className={styles.inputGroup}>
                      <Form.Label>Value</Form.Label>
                      <Form.Control
                        type='text'
                        value={voucher.amount}
                        readOnly={voucher.voucherType === 'INDIVIDUAL'}
                        onChange={(e) =>
                          this.handleInputChange(
                            index,
                            'amount',
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div className={styles.inputGroup}>
                      <Form.Label>Qty</Form.Label>
                      <Form.Control
                        type='number'
                        value={voucher.qty}
                        readOnly={voucher.voucherType === 'INDIVIDUAL'}
                        onChange={(e) =>
                          this.handleInputChange(index, 'qty', e.target.value)
                        }
                      />
                    </div>
                    <div className={styles.inputGroup}>
                      <Form.Label>Voucher Type</Form.Label>
                      <Form.Control
                        type='text'
                        value={voucher.voucherType}
                        readOnly
                      />
                    </div>
                  </Form.Group>

                  {/* Details Section */}
                  <div className={styles.detailsSection}>
                    {index === 0 && (
                      <button
                        type='button'
                        onClick={this.handleCopyForAll}
                        className={styles.copyButton}
                        title={
                          copyApplied
                            ? 'Details have been applied'
                            : voucherValues.length === 1
                            ? "'Copy for All' is disabled when there's only one voucher card"
                            : 'Copy these details to all voucher cards'
                        }
                        disabled={
                          voucherValues.length === 1 ||
                          !voucherValues[0].deliveryType ||
                          !voucherValues[0].packagingType ||
                          !voucherValues[0].brandingMaterial
                        }
                      >
                        <div className={styles.copyButtonContent}>
                          {copyApplied ? (
                            <FontAwesomeIcon
                              icon={faClipboardCheck}
                              style={{ color: '#56C729' }}
                            />
                          ) : (
                            <FontAwesomeIcon icon={faCopy} />
                          )}
                        </div>
                      </button>
                    )}

                    <Form.Group
                      controlId={`row2-${index}`}
                      className={styles.rowGroup}
                    >
                      <div className={styles.inputGroup}>
                        <Form.Label>Delivery Type</Form.Label>
                        <Form.Control
                          as='select'
                          value={voucher.deliveryType || ''}
                          onChange={(e) =>
                            this.handleInputChange(
                              index,
                              'deliveryType',
                              e.target.value
                            )
                          }
                          isInvalid={!voucher.deliveryType}
                        >
                          <option value='' disabled>
                            Select Delivery Type
                          </option>
                          <option value='E_VOUCHER_ONLY'>Voucher</option>
                          <option value='PHYSICAL_ONLY'>Physical</option>
                          <option value='E_VOUCHER_AND_PHYSICAL'>
                            E-Voucher & Physical
                          </option>
                        </Form.Control>
                        <Form.Control.Feedback type='invalid'>
                          Please select a Delivery Type
                        </Form.Control.Feedback>
                      </div>
                      <div className={styles.inputGroup}>
                        <Form.Label>Packaging Type</Form.Label>
                        <Form.Control
                          as='select'
                          value={voucher.packagingType || ''}
                          onChange={(e) => {
                            const selectedPackaging = packagingTypes.find(
                              (type) => type.name === e.target.value
                            );
                            this.handleInputChange(
                              index,
                              'packagingType',
                              e.target.value
                            );
                            if (selectedPackaging) {
                              this.handleInputChange(
                                index,
                                'packagingTypeId',
                                selectedPackaging.id
                              );
                            }
                          }}
                          isInvalid={!voucher.packagingType}
                        >
                          <option value='' disabled>
                            Select Packaging Type
                          </option>
                          {packagingTypes.map((type) => (
                            <option key={type.id} value={type.name}>
                              {type.name}
                            </option>
                          ))}
                        </Form.Control>
                        <Form.Control.Feedback type='invalid'>
                          Please select a Packaging Type
                        </Form.Control.Feedback>
                      </div>
                      <div className={styles.inputGroup}>
                        <Form.Label>Branding Material</Form.Label>
                        <Form.Control
                          as='select'
                          value={voucher.brandingMaterial || ''}
                          onChange={(e) =>
                            this.handleInputChange(
                              index,
                              'brandingMaterial',
                              e.target.value
                            )
                          }
                          isInvalid={!voucher.brandingMaterial}
                        >
                          <option value='' disabled>
                            Select Branding Material
                          </option>
                          <option value='COME_UP_FROM_SCRATCH'>
                            Come Up From Scratch
                          </option>
                          <option value='CUSTOMER_GAVE_LOGO'>
                            Customer Gave Logo
                          </option>
                          <option value='CUSTOMER_GAVE_LOGO_AND_BRAND_REQUIREMENT'>
                            Customer Gave Logo + Brand Req
                          </option>
                        </Form.Control>
                        <Form.Control.Feedback type='invalid'>
                          Please select a Branding Material
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>

                    <Form.Group
                      controlId={`row3-${index}`}
                      className={styles.checkboxRowGroup}
                    >
                      <div className={styles.checkboxInput}>
                        <Form.Label>Separate Bag</Form.Label>
                        <Form.Check
                          type='checkbox'
                          id={`separateBag-${index}`}
                          checked={voucher.separateBag || false}
                          onChange={(e) =>
                            this.handleCheckChange(
                              index,
                              'separateBag',
                              e.target.checked
                            )
                          }
                        />
                      </div>
                      <div className={styles.checkboxInput}>
                        <Form.Label>Co-Branded Voucher</Form.Label>
                        <Form.Check
                          type='checkbox'
                          id={`coBranded-${index}`}
                          checked={voucher.coBranded || false}
                          onChange={(e) =>
                            this.handleCheckChange(
                              index,
                              'coBranded',
                              e.target.checked
                            )
                          }
                        />
                      </div>
                    </Form.Group>

                    <Form.Group
                      controlId={`row4-${index}`}
                      className={styles.rowGroup}
                    >
                      <div className={styles.inputGroup}>
                        <Form.Label>Voucher Message</Form.Label>
                        <Form.Control
                          as='textarea'
                          rows={4}
                          value={voucher.voucherMessage || ''}
                          onChange={(e) =>
                            this.handleInputChange(
                              index,
                              'voucherMessage',
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </Form.Group>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          ))
        ) : (
          <p>No Voucher Values Available</p>
        )}
      </div>
    );
  }
}

export default VoucherValueCardToUpdate;
