import { POST, PUT } from './core';

export const getEndpointWithPrefix = (endpoint) => {
  return `/delivery/${endpoint}`;
};

export const getDeliveryOrders = (request, token) => {
  const endpoint = getEndpointWithPrefix('getDetails');
  return POST(endpoint, request, token).then((response) => {
    return response;
  });
};

export const updateStatus = (request, token) => {
  const endpoint = getEndpointWithPrefix('update');
  return PUT(endpoint, request, token).then((response) => {
    return response;
  });
};
