import React from 'react';
import styles from './stepProgressBar.module.scss';

const StepProgressBar = ({
  stepsStatus,
  currentStep,
  onStepClick,
  disabledSteps = [],
}) => {
  const steps = [
    'Place Order',
    'Generate Invoice',
    'Upload Slip',
    'Create Vouchers',
  ];

  return (
    <div className={styles.stepProgressContainer}>
      {steps.map((step, index) => {
        let circleClass = styles.stepCircle;
        if (stepsStatus[index]) {
          circleClass += ` ${styles.stepCompleted}`;
        } else if (index === currentStep) {
          circleClass += ` ${styles.stepCurrent}`;
        }

        let lineClass = styles.stepLine;
        if (stepsStatus[index]) {
          lineClass += ` ${styles.stepLineCompleted}`;
        }

        // plus any steps specified in disabledSteps.
        const isDisabled = disabledSteps.includes(index);

        return (
          <React.Fragment key={index}>
            <div
              className={`${styles.stepCircleContainer} ${
                isDisabled ? styles.disabled : ''
              }`}
              onClick={() => {
                if (isDisabled) return;
                if (onStepClick) onStepClick(index);
              }}
            >
              <div className={circleClass}>{index + 1}</div>
              <div className={styles.stepLabel}>
                {step.split(' ').map((word, i) => (
                  <div key={i}>{word}</div>
                ))}
              </div>
            </div>
            {index < steps.length - 1 && <div className={lineClass}></div>}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default StepProgressBar;
