import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  CloseButton,
  Dropdown,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { PageHeader, Pagination } from '../../components';
import CheckableDataTable from '../../components/CheckableDataTable/CheckableDataTable';
import useComponentState from '../../utils/useComponentState';
import { getDeliveryOrders, updateStatus } from '../../api/Orders';
import { Drawer } from 'rsuite';
import Search from '../../components/Search/Search.component';
import InputGroup from 'react-bootstrap/InputGroup';
import DropdownButton from 'react-bootstrap/DropdownButton';
import styles from './ViewOrders.module.scss';
import { copyToClipboard } from '../../utils/helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import DatePickerModal from '../../components/Modals/DatePickerModal';
import { toast } from 'react-toastify';
import { APP_PARAMS } from '../../constants/appParams';
import { useGetQueryParams } from '../../hooks/useGetQueryParams';
import { useSetQueryParams } from '../../hooks/useSetQueryParams';

const ViewOrders = (props) => {
  const queryParams = useGetQueryParams([
    APP_PARAMS.PAGE,
    APP_PARAMS.DELIVERY_TYPE,
    APP_PARAMS.DELIVERY_STATUS,
    APP_PARAMS.SEARCH_KEY,
    APP_PARAMS.SELECTED,
  ]);
  const setQueryParam = useSetQueryParams();
  /* const dispatch = useDispatch(); */
  const [state, setState] = useComponentState({
    tableHeading: {
      column: [
        'Purchase ID',
        'Created Date',
        'Deliver On/Before',
        'Voucher ID',
        'Receiver',
        'Dispatch',
        'Same Day',
        'Status',
        '',
      ],
    },
    orderData: [],
    tabItems: {
      orderType: [
        { key: null, label: 'ALL TYPES' },
        { key: 'COURIER_DELIVERY', label: 'DELIVERY' },
        { key: 'STORE_PICKUP', label: 'PICKUP' },
      ],
      orderStatus: [
        { key: null, label: 'ALL STATUSES' },
        { key: 'PROCESSING', label: 'PROCESSING' },
        { key: 'PICKUP_READY', label: 'PICKUP READY' },
        { key: 'PICKED', label: 'PICKED' },
        { key: 'DISPATCHED', label: 'DISPATCHED' },
        { key: 'DELIVERED', label: 'DELIVERED' },
      ],
      COURIER_DELIVERY: [
        { key: 'PROCESSING', label: 'PROCESSING' },
        { key: 'DISPATCHED', label: 'DISPATCHED' },
        { key: 'DELIVERED', label: 'DELIVERED' },
      ],
      STORE_PICKUP: [
        { key: 'PROCESSING', label: 'PROCESSING' },
        { key: 'PICKUP_READY', label: 'PICKUP READY' },
        { key: 'PICKED', label: 'PICKED' },
      ],
    },
    label: 'VoucherId',
    currentPage: queryParams.page - 1 || 0,
    pageSize: 25,
    searchPlaceholder: 'Voucher ID',
    searchId: 'id',
    searchKey: queryParams.searchKey || '',
    orderTypeKey:
      queryParams.deliveryType && queryParams.deliveryType !== 'ALL'
        ? queryParams.deliveryType
        : null,
    orderStatusKey:
      queryParams.deliveryStatus && queryParams.deliveryStatus !== 'ALL'
        ? queryParams.deliveryStatus
        : null,
    notFoundMessage: '',
    fetchError: '',
    loading: false,
    totalPages: 0,
    showDrawer: false,
    drawerData: null,
    isClearable: false,
    page: 1,
    validated: false,
    isSelected: false,
    selectAll: false,
    checkedRows: [],
    isloading: true,
    updateError: '',
    updateLoading: false,
    selectedPuchaseId: null,
    copied: false,
    showDatePickerModal: false,
    isCallApi: true,
  });

  const {
    tableHeading,
    orderData,
    loading,
    totalPages,
    currentPage,
    pageSize,
    showDrawer,
    drawerData,
    searchKey,
    isClearable,
    tabItems,
    orderTypeKey,
    orderStatusKey,
    fetchError,
    validated,
    isSelected,
    selectAll,
    checkedRows,
    orderStatus,
    updateError,
    updateLoading,
    selectedPuchaseId,
    copied,
    showDatePickerModal,
    isCallApi,
  } = state;

  useEffect(() => {
    if (!searchKey && !queryParams.searchKey) {
      getOrderDetails(0);
      setQueryParam({
        [APP_PARAMS.PAGE]: queryParams.page || 1,
        [APP_PARAMS.DELIVERY_TYPE]: orderTypeKey ? orderTypeKey : 'ALL',
        [APP_PARAMS.DELIVERY_STATUS]: orderStatusKey ? orderStatusKey : 'ALL',
        [APP_PARAMS.SELECTED]: null,
      });
    } else if (searchKey && queryParams.searchKey && isCallApi) {
      handleSearch();
    }

    //eslint-disable-next-line
  }, [orderTypeKey, orderStatusKey, searchKey]);

  const getOrderDetails = async (currentPage) => {
    setState({ loading: true, orderData: [], fetchError: '' });
    try {
      const request = {
        filterKey:
          queryParams.deliveryType && queryParams.deliveryType !== 'ALL'
            ? queryParams.deliveryType
            : orderTypeKey,
        filterByStatus:
          queryParams.deliveryStatus && queryParams.deliveryStatus !== 'ALL'
            ? queryParams.deliveryStatus
            : orderStatusKey,
        searchKey: searchKey || queryParams.searchKey || '',
        page: currentPage || (queryParams.page ? queryParams.page - 1 : 0),
        size: pageSize,
      };
      const response = await getDeliveryOrders(request, props.auth.auth.token);

      setState({
        orderData: response.deliveryDetailsList,
        totalPages: response.total,
        currentPage: response.current,
      });

      if (queryParams.selected) {
        const rowData = response.deliveryDetailsList.find(
          (data) => data.purchaseId === queryParams.selected
        );

        setQueryParam({ [APP_PARAMS.SELECTED]: rowData.purchaseId });
        setState({
          drawerData: rowData,
          showDrawer: true,
        });
      }
    } catch (error) {
      setState({
        fetchError: 'Oops, something went wrong. Please try again',
      });
    }
    setState({ loading: false });
  };

  const updateOrderStatus = async (status) => {
    setState({
      loading: true,
      fetchError: '',
    });
    try {
      const request = {
        purchaseId: drawerData.purchaseId,
        status,
        selectedDate: new Date().toISOString().split('T')[0],
        selectedTime: new Date().toTimeString().slice(0, 5),
        isMessageSent: null,
      };

      toast.promise(
        (async () => {
          const response = await updateStatus(request, props.auth.auth.token);
          if (response.message === 'DELIVERY_DETAILS_UPDATED_SUCCESSFULLY') {
            setState((prevState) => ({
              ...prevState,
              orderData: prevState.orderData.map((order) =>
                order.purchaseId === drawerData.purchaseId
                  ? { ...order, status }
                  : order
              ),
              drawerData: { ...prevState.drawerData, status },
              updateLoading: false,
            }));
          }
        })(),
        {
          pending: 'Updating order status...',
          success: 'Order status updated successfully',
          error: 'Oops, something went wrong. Please try again',
        }
      );
    } catch (error) {
      setState({
        updateError: 'Oops, something went wrong. Please try again',
        loading: false,
      });
    }
    setState({ updateLoading: false, loading: false });
  };

  const updateOrderPickupReady = () => {
    setState({ showDatePickerModal: true });
  };

  const handleDatePickerSave = async (
    selectedDate,
    selectedTime,
    isMessageSent
  ) => {
    setState({ loading: true, showDrawer: false });
    const request = {
      purchaseId: drawerData.purchaseId,
      status: 'PICKUP_READY',
      selectedDate,
      selectedTime,
      notifyToUser: false,
    };

    try {
      setState({ updateLoading: true });
      const response = await updateStatus(request, props.auth.auth.token);
      if (response.message === 'DELIVERY_DETAILS_UPDATED_SUCCESSFULLY') {
        setState({ updateLoading: false });
        getOrderDetails(0);
      }
    } catch (error) {
      setState({
        updateLoading: true,
        updateError: 'Oops, something went wrong. Please try again',
      });
    }
    setState({ updateLoading: false });
  };

  const handleOrderTypeChange = (key) => {
    setQueryParam({
      [APP_PARAMS.PAGE]: 1,
      [APP_PARAMS.DELIVERY_TYPE]: key,
      [APP_PARAMS.SEARCH_KEY]: null,
    });
    setState({
      orderTypeKey: key,
      searchKey: '',
      isClearable: false,
    });
  };

  const handleOrderStatusChange = (key) => {
    setQueryParam({
      [APP_PARAMS.PAGE]: 1,
      [APP_PARAMS.DELIVERY_STATUS]: key,
      [APP_PARAMS.SEARCH_KEY]: null,
    });
    setState({
      orderStatusKey: key,
      searchKey: '',
      isClearable: false,
    });
  };

  const copyRedemptionId = (text) => {
    copyToClipboard(text);
    setState({
      copied: true,
    });
    setTimeout(() => {
      setState({
        copied: false,
      });
    }, 2000);
  };

  const handleSearch = () => {
    if (!isClearable || queryParams.searchKey) {
      const { searchKey } = state;
      if (searchKey !== queryParams.searchKey) {
        setState({ isCallApi: true });
      }
      if (searchKey || queryParams.searchKey) {
        setQueryParam({
          [APP_PARAMS.SEARCH_KEY]: searchKey || queryParams.searchKey,
          [APP_PARAMS.DELIVERY_STATUS]: null,
          [APP_PARAMS.DELIVERY_TYPE]: null,
        });
        setState({
          searchKey: searchKey || queryParams.searchKey,
          isClearable: true,
        });
        getOrderDetails(0);
      }
    } else {
      handleIdOnClear();
    }
  };

  const handleIdOnClear = () => {
    setQueryParam({
      [APP_PARAMS.SEARCH_KEY]: null,
      [APP_PARAMS.DELIVERY_STATUS]: orderStatusKey,
      [APP_PARAMS.DELIVERY_TYPE]: orderTypeKey,
    });
    setState({
      searchKey: '',
      isClearable: false,
      notFoundMessage: null,
    });
    // getOrderDetails(0)
  };

  const handleCheckBoxToggle = (rowId, event) => {
    event.stopPropagation();
    handleCheck(rowId);
  };

  const handleCheck = (rowId) => {
    if (checkedRows.includes(rowId)) {
      const updatedCheckedRows = checkedRows.filter((id) => id !== rowId);
      setState({
        checkedRows: updatedCheckedRows,
      });
    } else {
      setState({
        checkedRows: [...checkedRows, rowId],
      });
    }
  };

  const handleSelectAll = (event) => {
    setState({ selectAll: event.target.checked });
    if (event.target.checked) {
      const selectedAll = orderData.map((data) => data.id);
      setState({
        checkedRows: [...new Set([...checkedRows, ...selectedAll])],
      });
    } else {
      const updatedCheckedRows = checkedRows.filter(
        (id) => !orderData.some((data) => data.id === id)
      );
      setState({
        checkedRows: updatedCheckedRows,
      });
    }
  };

  const searchOnChange = (value) => {
    setState({
      isCallApi: false,
      searchKey: value,
      orderTypeKey: null,
      orderStatusKey: null,
      isClearable: false,
    });
  };

  const handleStatusChange = (key) => {
    if (key === 'PICKUP_READY') {
      updateOrderPickupReady();
    } else {
      updateOrderStatus(key);
    }
  };

  const renderDeeplinkColumn = (id) => {
    const url = `${process.env.REACT_APP_MERCHANT_PORTAL_URL}/vouchers/view-vouchers?page=1&searchId=id&searchKey=${id}`;
    return (
      <div
        onClick={() => window.open(url, '_blank', 'noopener,noreferrer')}
        style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
      >
        <span>🡕</span>
      </div>
    );
  };

  const renderFilterByType = () => {
    const { orderTypeKey, tabItems } = state;

    return (
      <Dropdown style={{ marginRight: '10px' }}>
        <Dropdown.Toggle variant='primary' id='dropdown-basic'>
          {tabItems.orderType.find((item) => item.key === orderTypeKey)?.label}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {tabItems.orderType.map((item) => {
            const { key, label } = item;
            return (
              <Dropdown.Item
                key={key}
                active={
                  key === (queryParams.deliveryType === 'ALL')
                    ? null
                    : queryParams.deliveryType
                }
                onClick={() => handleOrderTypeChange(key)}
              >
                {label}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const renderFilterByStatus = () => {
    const { tabItems, orderStatusKey } = state;

    return (
      <Dropdown>
        <Dropdown.Toggle variant='primary' id='dropdown-basic'>
          {
            tabItems.orderStatus.find((item) => item.key === orderStatusKey)
              ?.label
          }
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {tabItems.orderStatus.map((item) => {
            const { key, label } = item;
            return (
              <Dropdown.Item
                key={key}
                active={
                  key === (queryParams.deliveryType === 'ALL')
                    ? null
                    : queryParams.deliveryType
                }
                onClick={() => handleOrderStatusChange(key)}
              >
                {label}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const handleOrderPagination = (page) => {
    setQueryParam({ [APP_PARAMS.PAGE]: page + 1 });
    if (searchKey) {
      getOrderDetails(page);
    } else {
      getOrderDetails(page);
    }
    setState({
      currentPage: page,
    });
  };

  const handleOnClick = (id) => {
    const rowData = orderData.find((data) => data.purchaseId === id);
    setQueryParam({ [APP_PARAMS.SELECTED]: rowData.purchaseId });
    setState({
      showDrawer: true,
      drawerData: rowData,
    });
  };

  const handleDrawerOnHide = () => {
    setQueryParam({
      [APP_PARAMS.SELECTED]: null,
    });
    setState({
      showDrawer: !showDrawer,
      transactionDetails: [],
      showTransactions: false,
      selectedTransaction: null,
    });
  };

  const renderDrawer = () => {
    const data = drawerData;
    const generalDetails = [
      [
        { header: 'Voucher ID', value: data.voucherId },
        {
          header: 'Voucher Amount',
          value: `LKR ${data.voucherAmount
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
        },
      ],

      [
        { header: 'Receiver', value: data.receiverName },
        {
          header: 'Creator Name (Thyaga Account Name)',
          value: data.creatorName,
        },
      ],
      [
        {
          header: data.deliveryMobile
            ? 'Delivery Mobile'
            : 'Creator/Picker Mobile',
          value: data.deliveryMobile ? data.deliveryMobile : data.creatorMobile,
        },
      ],
      [{ header: 'House Address', value: data.houseAddress }],
      [{ header: 'Landmark', value: data.landmarks }],
      [
        { header: 'City', value: data.city },
        { header: 'Order Status', value: 'orderStatus' },
      ],
      [
        {
          header: 'Add-ons',
          value: data.addons
            ? data.addons.map(({ name }) => {
                return (
                  <>
                    {name}
                    <br />
                  </>
                );
              })
            : '',
        },
      ],
      [
        {
          header: 'Delivery Charge',
          value:
            data.deliveryCharge != null
              ? `LKR ${data.deliveryCharge
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
              : 'NOT APPLICABLE',
        },
        {
          header: 'Addon Charges',
          value: data.addons
            ? `LKR ${data.addons
                .reduce((sum, obj) => sum + obj.price, 0)
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
            : 'NOT APPLICABLE',
        },
      ],
      [{ header: 'Delivery Note', value: data.deliveryNote }],

      ...(data.pickUpDate && data.pickUpTime
        ? [
            [
              { header: 'Pick Up Date', value: data.pickUpDate },
              { header: 'Pick Up Time', value: data.pickUpTime },
            ],
          ]
        : []),
    ];

    const renderDetails = (data) => {
      return (
        <>
          {data.map((row) => {
            if (row[0].conditionalRender && row[0].value === null) {
              return null;
            } else {
              return (
                <div key={row.id} className={styles.details__horizontal}>
                  {row.map((detailData, spanIndex) => (
                    <div
                      key={spanIndex}
                      className={styles.detail__group__nested}
                    >
                      <span className={styles.detail__header}>
                        {detailData.header}
                      </span>
                      {detailData.value === 'orderStatus' ? (
                        <DropdownButton
                          as={InputGroup.Prepend}
                          variant='secondary'
                          title={drawerData.status || 'PROCESSING'}
                          id='input-group-dropdown-1'
                        >
                          {tabItems[drawerData.physicalOption].map((item) => {
                            const { key, label } = item;
                            return (
                              <Dropdown.Item
                                key={key}
                                onClick={() => handleStatusChange(key)}
                              >
                                {label}
                              </Dropdown.Item>
                            );
                          })}
                        </DropdownButton>
                      ) : (
                        <span className={styles.detail__value}>
                          {detailData.value}
                        </span>
                      )}
                    </div>
                  ))}
                </div>
              );
            }
          })}

          {drawerData.pickUpReadySMS && (
            <div className={styles.pickup__ready__message}>
              <span className={styles.pickup__ready__message_header}>
                Sent Pickup Notification
                <OverlayTrigger
                  placement={'top'}
                  overlay={
                    <Tooltip>
                      {copied ? `Copied Message ✅` : `Click to copy Message`}
                    </Tooltip>
                  }
                >
                  <Button
                    onClick={() => copyRedemptionId(drawerData.pickUpReadySMS)}
                    variant='outline-dark'
                    size='sm'
                    className={styles.copy_icon}
                  >
                    <FontAwesomeIcon icon={faCopy} />
                  </Button>
                </OverlayTrigger>
              </span>
              <span
                className={styles.detail__value}
                dangerouslySetInnerHTML={{
                  __html: drawerData.pickUpReadySMS.replace('\n', '<br/><br/>'),
                }}
              />
            </div>
          )}
        </>
      );
    };

    return (
      <div className='purchase_drawer_root'>
        <div className='drawer_child'>
          <div className='header_group'>
            <div>
              <span className='header_title'>Purchase ID: </span>
              <span className='header_value'>
                {drawerData.purchaseId}
                <OverlayTrigger
                  placement={'top'}
                  overlay={
                    <Tooltip>
                      {copied
                        ? `Copied ${drawerData.purchaseId.toUpperCase()} ✅`
                        : `Click to copy ${drawerData.purchaseId.toUpperCase()}`}
                    </Tooltip>
                  }
                >
                  <Button
                    onClick={() =>
                      copyRedemptionId(drawerData.purchaseId.toUpperCase())
                    }
                    variant='outline-light'
                    size='sm'
                    className={styles.copy_icon}
                  >
                    <FontAwesomeIcon icon={faCopy} />
                  </Button>
                </OverlayTrigger>
              </span>
            </div>
            <div>
              <span className='header_title'>Mode: </span>
              <span className='header_value'>
                <strong>
                  {(() => {
                    const match = tabItems.orderType.find(
                      (s) => s.key === drawerData.physicalOption
                    );
                    return match && match.key !== null ? match.label : 'N/A';
                  })()}
                </strong>
              </span>
            </div>
          </div>
          <div className={styles.drawer__div}>
            <div className={styles.drawer__child}>
              {renderDetails(generalDetails)}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.root_div}>
      <div>
        <PageHeader text='Deliveries & Pickups' />
        <div className={styles.filter_block_status}>
          <span>Filter by Type & Order Status </span>
          <div className='d-flex my-3'>
            {renderFilterByType()}
            {renderFilterByStatus()}
          </div>
        </div>
        <div className={styles.search_area_block}>
          <div className={styles.search_area}>
            <Search
              placeholder={'Enter Puchase or Voucher ID'}
              onBtnPress={handleSearch}
              onClearPress={handleIdOnClear}
              onChange={searchOnChange}
              max={50}
              handleValueFromParent
              value={searchKey}
              searchKey={queryParams.searchKey}
            />
          </div>
        </div>
        <div className={styles.print_button_block}>
          <div className='mb-3 d-flex align-items-end'>
            {checkedRows.length > 0 && (
              <>
                <span style={{ lineHeight: '24px' }}>
                  {checkedRows.length} order
                  {checkedRows.length > 1 ? 's' : ''} selected
                </span>
                <span>
                  <CloseButton
                    className='ml-2'
                    onClick={() => {
                      setState({ checkedRows: [] });
                    }}
                  />
                </span>
              </>
            )}
          </div>
        </div>

        <div>
          <CheckableDataTable
            tableData={orderData}
            tableheading={tableHeading}
            isSelected={isSelected}
            tag='orders'
            fetchError={fetchError}
            notFoundMessage={fetchError}
            search_filter_loading={loading}
            searchFilterLoading={false}
            handleOnClick={handleOnClick}
            handleCheck={handleCheckBoxToggle}
            handleSelectAll={handleSelectAll}
            checkedRows={checkedRows}
            selectAll={selectAll}
            renderDeeplinkColumn={renderDeeplinkColumn}
          />
          {!loading && !fetchError && (
            <Pagination
              pageCount={totalPages}
              onChange={handleOrderPagination}
              currentPage={currentPage}
            />
          )}
        </div>
      </div>
      <Drawer show={showDrawer} onHide={handleDrawerOnHide}>
        {drawerData && renderDrawer()}
      </Drawer>

      <DatePickerModal
        show={showDatePickerModal}
        onClose={() => setState({ showDatePickerModal: false })}
        onSave={handleDatePickerSave}
      />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(ViewOrders);
