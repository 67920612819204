import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Checkbox } from 'rsuite';
import { Button, CloseButton } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import { PageHeader, Pagination, DrawerSeparator } from '../../components';
import CheckableDataTable from '../../components/CheckableDataTable/CheckableDataTable';
import useComponentState from '../../utils/useComponentState';
import { BulkPurchaseModal } from '../../components';
import { Drawer } from 'rsuite';
import {
  getPendingPurchases,
  searchPendingPurchases,
  listBulkComplete,
} from '../../api/Vouchers';
import styles from './PendingPurchases.module.scss';
import { toast } from 'react-toastify';
import SearchByDate from '../../components/SearchByDate/SearchByDate.components';
import Search from '../../components/Search/Search.component';
import { APP_PARAMS } from '../../constants/appParams';
import { useGetQueryParams } from '../../hooks/useGetQueryParams';
import { useSetQueryParams } from '../../hooks/useSetQueryParams';

const PendingPurchases = (props) => {
  const queryParams = useGetQueryParams([
    APP_PARAMS.PAGE,
    APP_PARAMS.START_DATE,
    APP_PARAMS.END_DATE,
    APP_PARAMS.SEARCH_KEY,
    APP_PARAMS.SELECTED,
  ]);
  const setQueryParam = useSetQueryParams();

  const [state, setState] = useComponentState({
    tableHeading: {
      column: [
        'Pending ID',
        'Purchase ID',
        'Created Date',
        'Created By',
        'Amount',
      ],
    },
    pendingPurchases: [],
    searchResults: [],
    isFilteredByDate: false,
    drawerData: null,
    totalPages: 0,
    currentPage: queryParams.page - 1 || 0,
    pageSize: 100,
    isSelected: false,
    loading: false,
    searchedMerchantMessage: '',
    checkedRows: [],
    requestArray: [],
    selectAll: false,
    notFoundMessage: '',
    showDrawer: false,
    drawerData: null,
    showBulkPurModal: false,
    fetchError: '',
    completeError: '',
    searchKey: queryParams.searchKey || '',
    startDate: null,
    endDate: null,
  });
  const {
    pendingPurchases,
    totalPages,
    currentPage,
    fetchError,
    tableHeading,
    requestArray,
    loading,
    pageSize,
    isSelected,
    checkedRows,
    selectAll,
    showDrawer,
    drawerData,
    notFoundMessage,
    showBulkPurModal,
    completeError,
    startDate,
    endDate,
    searchKey,
  } = state;

  useEffect(() => {
    if (
      ((startDate || queryParams.startDate) &&
        (endDate || queryParams.endDate)) ||
      searchKey ||
      queryParams.searchKey
    ) {
      searchPendingDetails(0);
    } else {
      getPendingDetails(0);
    }
    setQueryParam({
      [APP_PARAMS.PAGE]: queryParams.page || 1,
    });
    //eslint-disable-next-line
  }, [startDate, endDate]);

  const getPendingDetails = async (currentPage) => {
    setState({
      loading: true,
      pendingPurchases: [],
      fetchError: '',
      notFoundMessage: '',
    });
    try {
      const response = await getPendingPurchases(
        props.auth.auth.token,
        pageSize,
        currentPage
      );
      setState({
        pendingPurchases: response.list,
        totalPages: response.totalPageCount,
        currentPage: response.pageNo,
      });
      if (response.list.length === 0) {
        setState({ notFoundMessage: 'No pending purchases found' });
      }
    } catch (error) {
      setState({
        fetchError: 'Oops, something went wrong. Please try again',
      });
    }
    setState({ loading: false });
  };

  const handleSearch = () => {
    setQueryParam({ [APP_PARAMS.SEARCH_KEY]: searchKey });
    searchPendingDetails(0);
  };

  const searchPendingDetails = async (currentPage, isEmptyKey) => {
    setState({
      loading: true,
      pendingPurchases: [],
      fetchError: '',
      notFoundMessage: '',
    });
    try {
      const response = await searchPendingPurchases(
        props.auth.auth.token,
        queryParams.searchKey || (isEmptyKey ? '' : searchKey),
        queryParams.page ? queryParams.page - 1 : currentPage,
        pageSize,
        {
          startDate: startDate && formatDate(startDate),
          endDate: endDate && formatDate(endDate),
        }
      );
      let selected = null;
      if (queryParams.selected) {
        selected = response.list.find(
          (item) => item.id === queryParams.selected
        );
      }
      setState({
        drawerData: selected,
        showDrawer: selected ? true : false,
        pendingPurchases: response.list,
        totalPages: response.totalPageCount,
        currentPage: response.pageNo,
      });
      if (response.list.length === 0) {
        setState({ notFoundMessage: 'No pending purchases found' });
      }
    } catch (error) {
      setState({
        fetchError: 'Oops, something went wrong. Please try again',
      });
    }
    setState({ loading: false });
  };

  const handleCompletePurchases = async () => {
    setState({
      loading: true,
      completeError: '',
    });
    try {
      const token = props.auth.auth.token;
      toast.promise(listBulkComplete(requestArray, token), {
        pending: 'Completing purchases...',
        success: 'Purchases completed successfully',
        error: 'Oops, something went wrong. Please try again',
      });
    } catch (error) {
      setState({
        completeError: 'Oops, something went wrong. Please try again',
      });
    }
    setState({ loading: false });
  };

  const handlePagination = (page) => {
    setQueryParam({ [APP_PARAMS.PAGE]: page + 1 });
    getPendingDetails(page);
    setState({ curBatchPage: page });
  };

  const handleShowBulkPurModal = () => {
    setState({ showBulkPurModal: !showBulkPurModal });
  };

  const handleCheckBoxToggle = (rowId, event) => {
    event.stopPropagation();
    handleCheck(rowId);
  };

  const handleCheck = (rowId) => {
    if (checkedRows.includes(rowId)) {
      const updatedCheckedRows = checkedRows.filter((id) => id !== rowId);
      const updatedRequestArray = requestArray.filter(
        (item) => item.pendingVoucherId !== rowId
      );
      setState({
        checkedRows: updatedCheckedRows,
        requestArray: updatedRequestArray,
      });
      /* setCheckedRows(checkedRows.filter((id) => id !== rowId)); */
    } else {
      const newRequest = pendingPurchases
        .filter((item) => item.id === rowId)
        .map((item) => ({
          pendingVoucherId: item.id,
          purchaseId: item.purchaseId,
        }));
      setState({
        checkedRows: [...checkedRows, rowId],
        requestArray: [...requestArray, ...newRequest],
      });
      /* setCheckedRows([...checkedRows, rowId]); */
    }
  };

  const handleSelectAll = (event) => {
    setState({ selectAll: event.target.checked });
    if (event.target.checked) {
      const selectedAll = pendingPurchases.map((data) => data.id);
      const newArrayItems = pendingPurchases
        .filter((item) => !checkedRows.includes(item.id))
        .map((item) => ({
          pendingVoucherId: item.id,
          purchaseId: item.purchaseId,
        }));
      setState({
        checkedRows: [...new Set([...checkedRows, ...selectedAll])],
        requestArray: [...new Set([...requestArray, ...newArrayItems])],
      });
    } else {
      const updatedCheckedRows = checkedRows.filter(
        (id) => !pendingPurchases.some((data) => data.id === id)
      );
      const updatedRequestArray = updatedCheckedRows.map((id) =>
        requestArray.filter((item) => item.id === id)
      );
      setState({
        checkedRows: updatedCheckedRows,
        requestArray: updatedRequestArray,
      });
    }
  };

  const handleOnClick = (id) => {
    const rowData = pendingPurchases.find((data) => data.id === id);
    setQueryParam({ [APP_PARAMS.SELECTED]: rowData.id });
    setState({
      showDrawer: true,
      drawerData: rowData,
    });
  };

  const handleDrawerOnHide = () => {
    setQueryParam({ [APP_PARAMS.SELECTED]: null });
    setState({
      showDrawer: !showDrawer,
    });
  };

  const renderDrawer = () => {
    const data = drawerData;
    const physicalDelivery = data.city ? true : false;
    const generalDetails = [
      [
        { header: 'Voucher Name', value: data.voucherName },
        {
          header: 'Amount',
          value: `LKR ${data.amount
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
        },
      ],
      [
        { header: 'Sender', value: data.senderName },
        { header: 'Receiver', value: data.receiverName },
      ],
      [
        { header: 'Created By', value: data.createdBy },
        { header: 'Receiver Mobile', value: data.ownerMobile },
      ],
      [
        {
          header: 'Date Created',
          value: moment(data.createdDate)
            .add(5, 'hours')
            .add(30, 'minutes')
            .format('DD/MM/YYYY hh:mm A'),
        },
        {
          header: 'Valid Until',
          value: moment(data.expiryDate).format('DD/MM/YYYY hh:mm A'),
        },
      ],

      [{ header: 'Message', value: data.message }],
      [{ header: 'Modes of Delivery', value: 'renderModes' }],
      [
        {
          conditionalRender: true,
          header: 'Delivery Email',
          value: data.deliveryEmail,
        },
      ],
      [
        {
          conditionalRender: true,
          header: 'SMS/Email Delivery Date & Time',
          value:
            (data.smsDate &&
              moment(data.smsDate)
                .add(5, 'hours')
                .add(30, 'minutes')
                .format('DD/MM/YYYY hh:mm A')) ||
            (data.emailDate &&
              moment(data.emailDate)
                .add(5, 'hours')
                .add(30, 'minutes')
                .format('DD/MM/YYYY hh:mm A')),
        },
      ],
      [
        {
          header: 'Image Front',
          value: (
            <img
              style={{ maxWidth: '248.4px' }}
              src={data.imageFront}
              alt='Image Front'
            />
          ),
        },
        {
          header: 'Image Back',
          value: (
            <img
              style={{ maxWidth: '248.4px' }}
              src={data.imageBack}
              alt='Image Back'
            />
          ),
        },
      ],
    ];

    const deliveryDetails = [
      [{ header: 'Address', value: data.houseAddress }],
      [{ header: 'Landmarks', value: data.landmarks }],
      [
        { header: 'City', value: data.city },
        {
          header: 'Location URL',
          value: (
            <a target='_blank' href={data.deliveryLocationUrl}>
              {data.deliveryLocationUrl}
            </a>
          ),
        },
      ],
      [
        {
          header: 'Delivery Date',
          value: moment(data.deliveryDate)
            .add(5, 'hours')
            .add(30, 'minutes')
            .format('DD/MM/YYYY hh:mm A'),
        },
        {
          header: 'Delivery Charge',
          value:
            data.deliveryCharge &&
            `LKR ${data.deliveryCharge
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
        },
      ],
      [{ header: 'Delivery Mobile Number', value: data.deliveryMobileNumber }],
      [{ header: 'Delivery Note', value: data.deliveryNote }],
    ];

    const renderDeliveryModes = () => {
      const sms = data.smsMobileNumber ? true : false;
      const email = data.deliveryEmail ? true : false;
      const physicalDelivery = data.city ? true : false;

      return (
        <span className={styles.delivery__modes}>
          <Checkbox checked={sms}>SMS</Checkbox>
          <Checkbox checked={email}>Email</Checkbox>
          <Checkbox checked={physicalDelivery}>Physical Delivery</Checkbox>
        </span>
      );
    };

    const renderDetails = (detailType) => {
      return detailType.map((row) => {
        if (row[0].conditionalRender && row[0].value === null) {
          return null;
        } else {
          return (
            <div key={row.id} className={styles.details__horizontal}>
              {row.map((data, spanIndex) => (
                <div key={spanIndex} className={styles.detail__group__nested}>
                  <span className={styles.detail__header}>{data.header}</span>
                  {data.value === 'renderModes' ? (
                    renderDeliveryModes()
                  ) : (
                    <span className={styles.detail__value}>{data.value}</span>
                  )}
                </div>
              ))}
            </div>
          );
        }
      });
    };

    const renderDeliveryDetails = () => {
      return (
        <>
          <DrawerSeparator text='Physical Delivery Details' />
          {renderDetails(deliveryDetails)}
        </>
      );
    };
    /* const voucherID = data.id; */
    return (
      <div className='purchase_drawer_root'>
        <div className='drawer_child'>
          <div className='header_group'>
            <div>
              <span className='header_title'>Pending ID - </span>
              <span className='header_value'>{data.id}</span>
            </div>
            <div>
              <span className='header_title'>Purchase ID - </span>
              <span className='header_value'>{data.purchaseId}</span>
            </div>
          </div>
          <div className={styles.drawer__div}>
            <div className={styles.drawer__child}>
              {renderDetails(generalDetails)}
              {physicalDelivery && renderDeliveryDetails()}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const formatDate = (date) => {
    return moment(date).format('YYYY-MM-DD');
  };

  const searchOnChange = (value) => {
    setState({ searchKey: value });
  };

  const onChangeStartDate = (startDate) => {
    // setQueryParam({
    //   [APP_PARAMS.START_DATE]: startDate ? formatDate(startDate) : startDate,
    // });
    setState({ startDate });
  };

  const onChangeEndDate = (endDate) => {
    // setQueryParam({
    //   [APP_PARAMS.END_DATE]: endDate ? formatDate(endDate) : null,
    // });
    setState({ endDate });
  };

  const onClearPress = () => {
    setQueryParam({
      [APP_PARAMS.PAGE]: 1,
      [APP_PARAMS.SEARCH_KEY]: null,
    });
    setState({ searchKey: '' });
    if (startDate && endDate) {
      searchPendingDetails(0, true);
    } else {
      getPendingDetails(0);
    }
  };

  return (
    <div className={styles.root_div}>
      <div>
        <PageHeader text='Pending Purchases' />
        <div className={styles.search_area}>
          <div>
            <Search
              placeholder={
                'ID / Purchase ID / Sender / City / Amount / SMS Number / Receiver Mobile'
              }
              onBtnPress={() => handleSearch()}
              onClearPress={onClearPress}
              onChange={searchOnChange}
              max={50}
              handleValueFromParent
              value={searchKey}
              searchKey={queryParams.searchKey}
            />
            <SearchByDate
              onStartDateChange={onChangeStartDate}
              onEndDateChange={onChangeEndDate}
              startDate={startDate}
              endDate={endDate}
            />
          </div>
        </div>
        <div className={styles.print_button_block}>
          {/* Todo - render from a function*/}
          <div className='mb-3 d-flex align-items-end'>
            {checkedRows.length > 0 && (
              <>
                <span style={{ lineHeight: '24px' }}>
                  {checkedRows.length} voucher
                  {checkedRows.length > 1 ? 's' : ''} selected
                </span>
                <span>
                  <CloseButton
                    className='ml-2'
                    onClick={() => {
                      setState({ checkedRows: [] });
                    }}
                  />
                </span>
              </>
            )}
          </div>
          <div>
            <InputGroup className='mb-3'>
              <Button
                variant='secondary'
                onClick={() => handleShowBulkPurModal()}
              >
                Purchase Bulk
              </Button>
              {props.role === 'ROLE_THYAGA_ADMINISTRATOR' && (
                <Button
                  className='ml-3'
                  onClick={() => handleCompletePurchases()}
                  disabled={checkedRows.length === 0}
                >
                  Complete Purchases
                </Button>
              )}
            </InputGroup>
          </div>
        </div>
        <div>
          <CheckableDataTable
            tableData={pendingPurchases}
            tableheading={tableHeading}
            isSelected={isSelected}
            tag='pending-purchases'
            fetchError={fetchError}
            notFoundMessage={notFoundMessage}
            search_filter_loading={loading}
            searchFilterLoading={false}
            handleCheck={handleCheckBoxToggle}
            handleOnClick={handleOnClick}
            handleSelectAll={handleSelectAll}
            checkedRows={checkedRows}
            selectAll={selectAll}
          />
          {!fetchError && (
            <Pagination
              pageCount={totalPages}
              onChange={handlePagination}
              currentPage={currentPage}
            />
          )}
        </div>
      </div>
      <Drawer show={showDrawer} onHide={handleDrawerOnHide}>
        {drawerData && renderDrawer()}
      </Drawer>
      <BulkPurchaseModal
        token={props.auth.auth.token}
        show={showBulkPurModal}
        handleClose={handleShowBulkPurModal}
      ></BulkPurchaseModal>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    role: state.auth.auth.details.role,
  };
};
export default connect(mapStateToProps)(PendingPurchases);
