import React, { useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'react-phone-number-input/style.css';
import Spinner from 'react-bootstrap/Spinner';
import useComponentState from '../../utils/useComponentState';
import connectPrinter from '../../utils/connectPrinter';

const NameLabelModal = (props) => {
  const { show, labelsToPrint, handleClose } = props;

  const [
    {
      printLoading,
      printError,
      isAmount,
      isVoucherId,
      isPurchaseId,
      isSenderName,
      isReceiverName,
      labelItemLength,
    },
    setState,
  ] = useComponentState({
    printLoading: false,
    printError: false,
    isAmount: false,
    isVoucherId: false,
    isPurchaseId: false,
    isSenderName: false,
    isReceiverName: false,
    labelItemLength: 0,
  });

  const handleModalClose = () => {
    setState({
      printLoading: false,
      printError: false,
      isAmount: false,
      isVoucherId: false,
      isPurchaseId: false,
      isSenderName: false,
      isReceiverName: false,
      labelItemLength: 0,
    });
    handleClose();
  };

  const printNameLabels = async () => {
    let zpl = '';
    labelsToPrint.forEach(({ ownerName, sender, amount, id, purchaseId }) => {
      let topField,
        bottomField = null;
      if (labelItemLength > 1) {
        if (isSenderName && isReceiverName) {
          topField = ownerName;
          bottomField = sender;
        } else if (isSenderName && isAmount) {
          topField = sender;
          bottomField = `LKR ${amount
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
        } else if (isSenderName && isPurchaseId) {
          topField = sender;
          bottomField = purchaseId;
        } else if (isReceiverName && isAmount) {
          topField = ownerName;
          bottomField = `LKR ${amount
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
        } else if (isReceiverName && isPurchaseId) {
          topField = ownerName;
          bottomField = purchaseId;
        } else if (isAmount && isPurchaseId) {
          topField = purchaseId;
          bottomField = `LKR ${amount
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
        } else if (isVoucherId) {
          bottomField = id;
          topField =
            (isSenderName && sender) ||
            (isReceiverName && ownerName) ||
            (isAmount &&
              `LKR ${amount
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`) ||
            (isPurchaseId && purchaseId);
        }
      } else {
        topField =
          (isSenderName && sender) ||
          (isReceiverName && ownerName) ||
          (isAmount &&
            `LKR ${amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`) ||
          (isPurchaseId && purchaseId) ||
          (isVoucherId && id);
      }

      const fontsize =
        topField.length <= 20 ? 45 : topField.length <= 30 ? 40 : 25;

      const amountOffset =
        topField.length <= 20 ? 105 : topField.length <= 30 ? 100 : 95;

      zpl += `^XA
^MD22
^FO0,${labelItemLength > 1 ? '50' : '65'}
^FB799,1,0,C,0
^CF0,${fontsize},${fontsize}
^C^FH\^FD${topField ? topField : ''}^FS

      ${
        bottomField
          ? `^FO0,${amountOffset}
      ^FB799,1,0,C,0
      ^A0N,25,25
      ^C^FH\^FD${bottomField}^FS`
          : ''
      }

^FO0,160
^FB799,1,0,C,0
^A0N,20,20
^C^FH\^FDOne voucher, 150+ merchants | thyaga.lk^FS
^XZ`;
    });

    try {
      const device = await connectPrinter();
      const encoder = new TextEncoder();
      const data = encoder.encode(zpl);

      await device.transferOut(1, data);
    } catch (error) {
      setState({ printError: true });
    }
  };

  const handleCheck = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      setState({ labelItemLength: labelItemLength + 1 });
    } else {
      setState({ labelItemLength: labelItemLength - 1 });
    }
    setState({ [name]: checked });
  };

  return (
    <Modal
      show={show}
      style={{ opacity: 1 }}
      animation={false}
      centered
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>Print Name Labels</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row style={{ paddingBottom: '1rem' }}>
          <Col sm>Choose the fields limited to a maximum of two</Col>
        </Row>

        <Row>
          <Col sm>
            <Form.Group>
              <Form.Check
                reverse
                checked={isReceiverName}
                disabled={labelItemLength === 2 && !isReceiverName}
                onChange={handleCheck}
                type='checkbox'
                name='isReceiverName'
                readOnly
                label={`Receiver Name`}
              />
              <Form.Check
                reverse
                checked={isSenderName}
                disabled={labelItemLength === 2 && !isSenderName}
                onChange={handleCheck}
                type='checkbox'
                name='isSenderName'
                readOnly
                label={`Sender Name`}
              />
              <Form.Check
                reverse
                checked={isPurchaseId}
                disabled={labelItemLength === 2 && !isPurchaseId}
                onChange={handleCheck}
                type='checkbox'
                name='isPurchaseId'
                readOnly
                label={`Purchase ID`}
              />
              <Form.Check
                reverse
                checked={isAmount}
                disabled={labelItemLength === 2 && !isAmount}
                onChange={handleCheck}
                type='checkbox'
                name='isAmount'
                readOnly
                label={`Amount`}
              />
              <Form.Check
                reverse
                checked={isVoucherId}
                disabled={labelItemLength === 2 && !isVoucherId}
                onChange={handleCheck}
                type='checkbox'
                name='isVoucherId'
                readOnly
                label={`Voucher Id`}
              />
            </Form.Group>
          </Col>
        </Row>
        {printError && (
          <small className='text-center text-danger'>
            Oops, something went wrong! <br />
            <strong>Check your cables & make sure you are using chrome</strong>
          </small>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={() => handleModalClose()}>
          Cancel
        </Button>

        <Button
          variant='primary'
          onClick={() => printNameLabels()}
          disabled={printLoading}
          style={{ width: '90px' }}
        >
          {printLoading ? <Spinner size='sm' animation='border' /> : 'Print'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NameLabelModal;
