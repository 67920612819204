import React, { useEffect } from 'react';
import styles from './OrderDetails.module.scss';
import PageHeader from '../pageHeader/PageHeader.component';
import {
  Button,
  Col,
  Dropdown,
  Form,
  FormControl,
  InputGroup,
  Row,
} from 'react-bootstrap';
import useComponentState from '../../utils/useComponentState';
import { getAllClients, updatePlaceOrder } from '../../api/Client';
import { connect } from 'react-redux';
import { getAllSalesAgents } from '../../api/clientOrder';
import VoucherValueCardToUpdate from '../VoucherValueCardToUpdate/VoucherValueCardToUpdate';
import { formatPackagingName } from '../../utils/formatNames';
import { getAllPackagingType } from '../../api/Vouchers';
import { toast } from 'react-toastify';

const OrderDetails = (props) => {
  const [state, setState] = useComponentState({
    selectedClient: props.activeOrder.clientName,
    clientId: props.activeOrder.clientId,
    salesAgentName: props.activeOrder.salesAgentName,
    salesAgentId: props.activeOrder.salesAgentId,
    contactPointName: props.activeOrder.contactPointName,
    contactPointEmail: props.activeOrder.contactPointEmail,
    agentList: [],
    clientList: [],
    agentSearchTerm: '',
    clientSearchTerm: '',
    primaryFormValidated: false,
    csvVoucherValues: props.activeOrder.orderItems || [],
    manualVoucherValues: [],
    packagingTypes: [],
    loading: false,
    error: null,
  });

  useEffect(() => {
    getClients();
    getAgents();
    getPackagingTypes();
  }, []);

  const sortClientsList = (data) =>
    data.sort((a, b) =>
      a.clientName.toLowerCase() > b.clientName.toLowerCase() ? 1 : -1
    );

  const sortAgentsList = (data) =>
    data.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));

  const getClients = async () => {
    try {
      const response = await getAllClients(props.auth.auth.token);
      const sortedList = sortClientsList(response.list);
      setState({ clientList: sortedList });
    } catch (err) {
      setState({ loading: false, error: err.message });
    }
  };

  const getAgents = async () => {
    try {
      const response = await getAllSalesAgents(props.auth.auth.token);
      const sortedList = sortAgentsList(response);
      setState({ agentList: sortedList });
    } catch (err) {
      setState({ loading: false, error: err.message });
    }
  };

  const getPackagingTypes = async () => {
    try {
      const response = await getAllPackagingType(props.auth.auth.token);
      const formattedPackagingTypes = response.map((item) => ({
        ...item,
        name: formatPackagingName(item.name),
      }));
      setState({ packagingTypes: formattedPackagingTypes });
    } catch (err) {
      setState({ loading: false, error: err.message });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ [name]: value });
  };

  const handleRemoveCsvVoucher = (index) => {
    const updatedCsvVouchers = [...state.csvVoucherValues];
    updatedCsvVouchers.splice(index, 1);
    setState({ csvVoucherValues: updatedCsvVouchers });
  };

  const handleUpdateCsvVoucherByInput = (index, field, value) => {
    const updatedCsvVouchers = [...state.csvVoucherValues];
    if (updatedCsvVouchers[index]) {
      updatedCsvVouchers[index][field] = value;
      setState({ csvVoucherValues: updatedCsvVouchers });
    } else {
      console.error(`Voucher at index ${index} does not exist.`);
    }
  };

  const handleUpdateCsvVoucherByCheck = (index, field, isChecked) => {
    const updatedCsvVouchers = [...state.csvVoucherValues];
    updatedCsvVouchers[index][field] = isChecked;
    setState({ csvVoucherValues: updatedCsvVouchers });
  };

  const handleUpdatePlaceOrder = async () => {
    // Combine both voucher arrays into one list
    const orderItems = [
      ...state.csvVoucherValues,
      ...state.manualVoucherValues,
    ];

    // Transform each voucher object to match the backend expected structure.
    // Mapping "id" to "itemQtyDetailId" and extracting packagingType.id to "packagingTypeId"
    const transformedItems = orderItems.map((item) => ({
      itemQtyDetailId: item.id,
      amount: item.amount,
      qty: item.qty,
      deliveryType: item.deliveryType,
      packagingTypeId: item.packagingType ? item.packagingType.id : null,
      coBranded: item.coBranded,
      brandingMaterial: item.brandingMaterial,
      separateBag: item.separateBag,
      voucherMessage: item.voucherMessage,
      voucherType: item.voucherType,
    }));

    // Construct the payload to match the backend request structure
    const payload = {
      clientOrderId: props.activeOrder.clientOrderId,
      status: props.activeOrder.status,
      clientId: state.clientId,
      salesAgentId: state.salesAgentId,
      contactPointName: state.contactPointName,
      contactPointEmail: state.contactPointEmail,
      itemQuantityDetailList: transformedItems,
      clientOrderItemDetailList: null,
    };

    // API call to update place order can be done here:
    const response = await updatePlaceOrder(props.auth.auth.token, payload);
    if (response.message === 'Client order updated successfully') {
      toast.success('Order updated successfully!');
      props.updateProgress(props.activeOrder);
    } else {
      toast.error('Failed to update order.');
    }
  };

  const renderAgentDropDown = () => {
    const filteredAgents = state.agentSearchTerm
      ? state.agentList.filter((agent) =>
          agent.name
            .toLowerCase()
            .startsWith(state.agentSearchTerm.toLowerCase())
        )
      : state.agentList;

    return (
      <Dropdown className='w-100'>
        <Dropdown.Toggle variant='light' id='agent-dropdown' className='w-100'>
          {props.activeOrder?.salesAgentName || 'Select A Sales Agent'}
        </Dropdown.Toggle>
        <Dropdown.Menu className='w-100'>
          <div className='px-3 py-2'>
            <InputGroup>
              <FormControl
                placeholder='Search agents...'
                value={state.agentSearchTerm}
                onChange={(e) => setState({ agentSearchTerm: e.target.value })}
              />
            </InputGroup>
          </div>
          {filteredAgents.length > 0 ? (
            filteredAgents.map((agent) => (
              <Dropdown.Item
                key={agent.id}
                onClick={() =>
                  setState({
                    salesAgentName: agent.name,
                    salesAgentId: agent.id,

                    agentSearchTerm: '',
                  })
                }
              >
                {agent.name}
              </Dropdown.Item>
            ))
          ) : (
            <Dropdown.Item disabled>No results found</Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const renderClientDropDown = () => {
    const filteredClients = state.clientSearchTerm
      ? state.clientList.filter((client) =>
          client.clientName
            .toLowerCase()
            .startsWith(state.clientSearchTerm.toLowerCase())
        )
      : state.clientList;

    return (
      <Dropdown className='w-100'>
        <Dropdown.Toggle variant='light' id='client-dropdown' className='w-100'>
          {state.selectedClient || 'Select A Client'}
        </Dropdown.Toggle>
        <Dropdown.Menu className='w-100'>
          <div className='px-3 py-2'>
            <InputGroup>
              <FormControl
                placeholder='Search clients...'
                value={state.clientSearchTerm}
                onChange={(e) => setState({ clientSearchTerm: e.target.value })}
              />
            </InputGroup>
          </div>
          {filteredClients.length > 0 ? (
            filteredClients.map((client) => (
              <Dropdown.Item
                key={client.id}
                onClick={() =>
                  setState({
                    selectedClient: client.clientName,
                    clientId: client.id,
                    clientSearchTerm: '',
                  })
                }
              >
                {client.clientName}
              </Dropdown.Item>
            ))
          ) : (
            <Dropdown.Item disabled>No results found</Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const renderInputField = (name, label, value, onChange, max, customInput) => (
    <Form.Group as={Row}>
      <Col>
        <Form.Label>{label}</Form.Label>
        {customInput ? (
          customInput
        ) : (
          <Form.Control
            name={name}
            value={value}
            onChange={onChange}
            maxLength={max}
            required={name !== 'commission'}
            placeholder={
              name === 'imgUrl'
                ? 'https://example.com/sample_image.png'
                : `Enter ${label.toLowerCase()}`
            }
          />
        )}
        <Form.Control.Feedback type='invalid' className={styles.error_msg}>
          {name === 'email'
            ? 'Email address cannot be empty'
            : `${label} cannot be empty`}
        </Form.Control.Feedback>
      </Col>
    </Form.Group>
  );

  return (
    <div className={styles.div_root}>
      <PageHeader text='Update Client Order' />
      <Form
        noValidate
        validated={state.primaryFormValidated}
        style={{ marginTop: '20px' }}
      >
        <Row>
          <Col>
            {renderInputField(
              'clientId',
              'Client Name',
              state.selectedClient,
              handleInputChange,
              255,
              renderClientDropDown()
            )}
          </Col>
          <Col>
            {renderInputField(
              'salesAgent',
              'Sales Agent',
              state.salesAgentName || '',
              handleInputChange,
              320,
              renderAgentDropDown()
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            {renderInputField(
              'contactPointName',
              'Contact point name',
              state.contactPointName,
              handleInputChange,
              50
            )}
          </Col>
          <Col>
            {renderInputField(
              'contactPointEmail',
              'Contact point Email',
              state.contactPointEmail,
              handleInputChange,
              50
            )}
          </Col>
        </Row>
        {(state.csvVoucherValues.length > 0 ||
          state.manualVoucherValues.length > 0) && (
          <hr className={styles.boldHr} />
        )}
        <div style={{ marginTop: '20px' }}>
          <h5 style={{ marginTop: '20px', textAlign: 'center' }}>
            Voucher Values To Update
          </h5>
          <VoucherValueCardToUpdate
            voucherValues={state.csvVoucherValues}
            packagingTypes={state.packagingTypes}
            onUpdateByInput={handleUpdateCsvVoucherByInput}
            onUpdateByCheck={handleUpdateCsvVoucherByCheck}
            onRemove={handleRemoveCsvVoucher}
          />
        </div>
        {state.error && <p className='text-danger'>{state.error}</p>}
        <div className='d-flex justify-content-center'>
          <Button
            variant='dark'
            size='lg'
            onClick={handleUpdatePlaceOrder}
            disabled={
              state.loading ||
              [...state.csvVoucherValues, ...state.manualVoucherValues].some(
                (voucher) =>
                  !voucher.deliveryType ||
                  !voucher.packagingType ||
                  !voucher.brandingMaterial
              )
            }
            style={{ marginTop: '20px', width: '100%' }}
          >
            {state.loading ? 'Updating...' : 'Update Client Order'}
          </Button>
        </div>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(OrderDetails);
